import React from 'react';
import { createUseStyles, Theme } from 'react-jss';
import { KlimraTheme } from "styles/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: (props: InfoSectionProps) => theme.colors[props.bg || 'primary'],
  },
  section: {
    margin: '0 auto',
    padding: '1.25rem',
    maxWidth: '1000px',
  },
  topTitle: {
    fontWeight: 'bold',
    fontSize: '1.125rem',
    '@media (min-width: 640px)': {
      fontSize: '1.25rem',
    },
  },
  article: {
    margin: '2rem 0',
    fontSize: '1rem',
    '@media (min-width: 640px)': {
      fontSize: '1.125rem',
    },
    "& p": {
      marginBottom: '1.5rem',
    }
  },
  title: {
    fontWeight: '600',
    fontSize: '1.875rem',
    '@media (min-width: 640px)': {
      fontSize: '2.25rem',
    },
    marginBottom: '3rem',
  },
  subtitle: {
    fontSize: '1.125rem',
    fontWeight: 'normal',
    '@media (min-width: 640px)': {
      fontSize: '1rem',
    },
  },
}));

interface InfoSectionProps {
  bg?: keyof typeof KlimraTheme.colors;
  topTitle?: string;
  style?: string;
  sections: {
    title: string;
    subtitle?: string;
    content: string[];
    style?: string;
  }[];
}

export const InfoSection: React.FC<InfoSectionProps> = ({ topTitle, style, bg, sections }) => {
  const classes = useStyles({ bg, sections });

  return (
    <div className={classes.container}>
      <section className={`${classes.section} ${style || ''}`}>
        {topTitle && (
          <>
            <p className={classes.topTitle}>{topTitle}</p>
          </>
        )}
        {sections.map((section, index) => (
          <article key={index} className={`${classes.article} ${section.style || ''}`}>
            <h1 className={classes.title}>{section.title}</h1>
            {section.subtitle && <h2 className={classes.subtitle}>{section.subtitle}</h2>}
            {section.content.map((content, contentIndex) => (
              <React.Fragment key={contentIndex}>
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </React.Fragment>
            ))}
          </article>
        ))}
      </section>
    </div>
  );
};
