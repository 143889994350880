import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "70%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {}

export const Terms: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection}>
      <article>
        <h1 style={{ textAlign: "center" }}>Användarvillkor</h1>
        <p>Senast uppdaterad juli 28, 2024</p>
      </article>

      <article>
        <h2>
          <strong>GODKÄNNANDE AV VÅRA JURIDISKA VILLKOR</strong>
        </h2>
        <p>
          Vi är Klimra AB, ett bolag registrerat i Sverige med adress Drömstigen
          41, Stockholm, 16761 Stockholm.
          <br />
          <br />
          Vi driver webbplatsen https://www.klimra.com, samt alla andra
          relaterade produkter och tjänster som hänvisar eller länkar till dessa
          juridiska villkor.
          <br />
          <br />
          Du kan kontakta oss via telefon på 0761608084, e-post på
          contact@klimra.com, eller via post till Östermalmsgatan 26A, Stockholm
          11426, Sverige.
          <br />
          <br />
          Dessa juridiska villkor utgör ett juridiskt bindande avtal mellan dig,
          antingen personligen eller på uppdrag av en enhet ("du"), och Klimra
          AB, om din tillgång till och användning av tjänsterna. Du samtycker
          till att genom att få tillgång till Tjänsterna har du läst, förstått
          och samtyckt till att vara bunden av alla dessa juridiska villkor. OM
          DU INTE SAMTYCKER TILL ALLA DESSA JURIDISKA VILLKOR ÄR DU UTTRYCKLIGEN
          FÖRBJUDEN ATT ANVÄNDA TJÄNSTERNA OCH DU MÅSTE OMEDELBART AVBRYTA
          ANVÄNDNINGEN.
          <br />
          <br />
          Vi kommer att ge dig förhandsmeddelande om eventuella planerade
          ändringar av de tjänster du använder. De ändrade juridiska villkoren
          kommer att träda i kraft när de publiceras eller meddelas dig via
          Contact@klimra.com, enligt vad som anges i e-postmeddelandet. Genom
          att fortsätta använda Tjänsterna efter ikraftträdandedatumet för
          eventuella ändringar samtycker du till att vara bunden av de ändrade
          villkoren.
          <br />
          br Tjänsterna är avsedda för användare som är minst 16 år gamla.
          Personer under 16 år får inte använda eller registrera sig för
          Tjänsterna.
        </p>
      </article>

      <article>
        <h2>
          <strong>1. VÅRA TJÄNSTER</strong>
        </h2>
        <p>
          Den information som tillhandahålls vid användning av Tjänsterna är
          inte avsedd att distribueras till eller användas av någon person eller
          enhet i någon jurisdiktion eller något land där sådan distribution
          eller användning skulle strida mot lag eller förordning eller som
          skulle göra oss föremål för något registreringskrav i sådan
          jurisdiktion eller sådant land. Följaktligen gör de personer som
          väljer att få tillgång till tjänsterna från andra platser detta på
          eget initiativ och är ensamt ansvariga för att följa lokala lagar, om
          och i den utsträckning lokala lagar är tillämpliga.
        </p>
      </article>

      <article>
        <h2>
          <strong>2. IMMATERIELLA RÄTTIGHETER</strong>
        </h2>
        <p>
          <strong>Våra immateriella rättigheter</strong>
        </p>
        <p>
          Vi är ägare eller licenstagare till alla immateriella rättigheter i
          våra tjänster, inklusive all källkod, databaser, funktionalitet,
          programvara, webbplatsdesign, ljud, video, text, fotografier och
          grafik i tjänsterna (tillsammans "innehållet"), samt de varumärken,
          servicemärken och logotyper som finns däribland ("märkena")
          <br />
          <br />
          Vårt innehåll och våra märken skyddas av upphovsrätts- och
          varumärkeslagar (och olika andra immateriella rättigheter och lagar om
          illojal konkurrens) och fördrag i USA och runt om i världen.
          <br />
          <br />
          Innehållet och märkena tillhandahålls i eller via tjänsterna "SOM DE
          ÄR" endast för din personliga, icke-kommersiella användning eller
          interna affärsändamål.
        </p>
        <p>
          <strong>Din användning av våra tjänster</strong>
        </p>
        <p>
          Under förutsättning att du följer dessa juridiska villkor, inklusive
          avsnittet "FÖRBJUDNA AKTIVITETER" nedan, ger vi dig en icke-exklusiv,
          icke-överlåtbar, återkallelig licens att:
          <br />
          <br />
          · Få tillgång till tjänsterna; och
          <br />
          · Ladda ner eller skriva ut en kopia av någon del av innehållet som du
          har fått tillgång till på rätt sätt
          <br />
          <br />
          enbart för din personliga, icke-kommersiella användning eller interna
          affärsändamål.
          <br />
          <br />
          Med undantag för vad som anges i detta avsnitt eller på annan plats i
          våra juridiska villkor får ingen del av tjänsterna och inget innehåll
          eller märken kopieras, reproduceras, sammanställas, publiceras på
          nytt, laddas upp, publiceras, visas offentligt, kodas, översättas,
          överföras, distribueras, säljas, licensieras eller på annat sätt
          utnyttjas för något som helst kommersiellt ändamål utan vårt
          uttryckliga skriftliga tillstånd i förväg.
          <br />
          <br />
          Om du vill använda tjänsterna, innehållet eller varumärkena på annat
          sätt än vad som anges i detta avsnitt eller på annat ställe i våra
          juridiska villkor, vänligen skicka din begäran till:
          contact@klimra.com. Om vi ger dig tillstånd att publicera, reproducera
          eller offentligt visa någon del av våra tjänster eller vårt innehåll,
          måste du identifiera oss som ägare eller licensgivare av tjänsterna,
          innehållet eller märkena och se till att alla meddelanden om
          upphovsrätt eller äganderätt visas eller är synliga när du publicerar,
          reproducerar eller visar vårt innehåll.
          <br />
          <br />
          Vi förbehåller oss alla rättigheter som inte uttryckligen beviljas dig
          i och till Tjänsterna, Innehållet och Märkena.
          <br />
          <br />
          Varje brott mot dessa immateriella rättigheter kommer att utgöra ett
          väsentligt brott mot våra juridiska villkor och din rätt att använda
          våra tjänster kommer att upphöra omedelbart.
        </p>

        <p>
          <strong>Dina inlägg</strong>
        </p>
        <p>
          Läs igenom detta avsnitt och avsnittet "FÖRBJUDNA AKTIVITETER"
          noggrant innan du använder våra tjänster för att förstå (a) de
          rättigheter du ger oss och (b) de skyldigheter du har när du
          publicerar eller laddar upp innehåll via tjänsterna.
          <br />
          <br />
          Inlämningar: Genom att direkt skicka oss frågor, kommentarer, förslag,
          idéer, feedback eller annan information om tjänsterna ("Inlämningar")
          samtycker du till att tilldela oss alla immateriella rättigheter i
          sådana Inlämningar. Du samtycker till att vi ska äga detta bidrag och
          ha rätt till dess obegränsade användning och spridning för alla
          lagliga ändamål, kommersiella eller på annat sätt, utan erkännande
          eller kompensation till dig.
          <br />
          <br />
          Du är ansvarig för vad du publicerar eller laddar upp: Genom att
          skicka oss Inlämningar genom någon del av Tjänsterna:
          <br />
          <br />
          · Bekräftar du att du har läst och samtycker till våra "FÖRBJUDNA
          AKTIVITETER" och inte kommer att publicera, skicka, publicera, ladda
          upp eller överföra via tjänsterna någon inskickad information som är
          olaglig, trakasserande, hatisk, skadlig, ärekränkande, obscen,
          mobbande, kränkande, diskriminerande, hotfull mot någon person eller
          grupp, sexuellt explicit, falsk, felaktig, bedräglig eller
          vilseledande:
          <br />
          <br />
          · i den utsträckning det är tillåtet enligt tillämplig lag, avstår du
          från alla moraliska rättigheter till sådana inlämningar;
          <br />
          <br />
          · garanterar du att alla sådana Bidrag är ditt original eller att du
          har de nödvändiga rättigheterna och licenserna för att skicka sådana
          Bidrag och att du har full befogenhet att bevilja oss de ovan nämnda
          rättigheterna i förhållande till dina Bidrag; och
          <br />
          <br />
          · garanterar och intygar du att dina bidrag inte utgör konfidentiell
          information.
          <br />
          <br />
          Du är ensam ansvarig för dina inlämningar och du samtycker
          uttryckligen till att ersätta oss för alla förluster som vi kan
          drabbas av på grund av ditt brott mot (a) detta avsnitt, (b) tredje
          parts immateriella rättigheter eller (c) tillämplig lag.
        </p>
      </article>

      <article>
        <h2>
          <strong>3. ANVÄNDARENS UTFÄSTELSER</strong>
        </h2>
        <p>
          Genom att använda Tjänsterna försäkrar och garanterar du att: (1) all
          registreringsinformation som du lämnar kommer att vara sann, korrekt,
          aktuell och fullständig; (2) du kommer att upprätthålla riktigheten i
          sådan information och omedelbart uppdatera sådan
          registreringsinformation vid behov; (3) du har rättskapacitet och du
          samtycker till att följa dessa juridiska villkor; (4) du inte är
          minderårig i den jurisdiktion där du är bosatt; (5) du inte kommer att
          få tillgång till Tjänsterna genom automatiserade eller icke-mänskliga
          medel, vare sig genom en bot, ett skript eller på annat sätt; (6) du
          inte kommer att använda Tjänsterna för något olagligt eller obehörigt
          ändamål; och (7) din användning av Tjänsterna inte kommer att bryta
          mot någon tillämplig lag eller förordning.
          <br />
          <br />
          Om du lämnar information som är osann, felaktig, inaktuell eller
          ofullständig har vi rätt att stänga av eller avsluta ditt konto och
          vägra all nuvarande eller framtida användning av tjänsterna (eller
          någon del därav).
        </p>
      </article>

      <article>
        <h2>
          <strong>4. ANVÄNDARREGISTRERING</strong>
        </h2>
        <p>
          Du behöver registrera dig för att använda tjänsterna. Du samtycker
          till att hålla ditt lösenord konfidentiellt och kommer att vara
          ansvarig för all användning av ditt konto och lösenord. Vi förbehåller
          oss rätten att ta bort, återkräva eller ändra ett användarnamn som du
          väljer om vi efter eget gottfinnande anser att ett sådant användarnamn
          är olämpligt, obscent eller på annat sätt stötande.
        </p>
      </article>

      <article>
        <h2>
          <strong>5. FÖRBJUDNA AKTIVITETER</strong>
        </h2>
        <p>
          Du får inte komma åt eller använda Tjänsterna för något annat ändamål
          än det för vilket vi gör Tjänsterna tillgängliga. Tjänsterna får inte
          användas i samband med några kommersiella strävanden förutom de som
          specifikt stöds eller godkänns av oss.
          <br />
          <br />
          Som användare av Tjänsterna samtycker du till att inte göra följande:
          <br />
          <br />
          · Systematiskt hämta data eller annat innehåll från tjänsterna för att
          skapa eller kompilera, direkt eller indirekt, en samling,
          sammanställning, databas eller katalog utan skriftligt tillstånd från
          oss.
          <br />
          <br />
          · Lura, bedra eller vilseleda oss och andra användare, särskilt i alla
          försök att lära sig känslig kontoinformation som användarlösenord.
          <br />
          <br />
          · Kringgå, inaktivera eller på annat sätt störa säkerhetsrelaterade
          funktioner i tjänsterna, inklusive funktioner som förhindrar eller
          begränsar användningen eller kopieringen av något innehåll eller
          upprätthåller begränsningar för användningen av tjänsterna och/eller
          innehållet däri.
          <br />
          <br />
          · Nedvärdera, förnedra eller på annat sätt skada oss och/eller
          tjänsterna enligt vår åsikt.
          <br />
          <br />
          · Använd all information som erhållits från tjänsterna för att
          trakassera, missbruka eller skada en annan person.
          <br />
          <br />
          · Göra felaktig användning av våra supporttjänster eller skicka in
          falska rapporter om missbruk eller tjänstefel.
          <br />
          <br />
          · Använd tjänsterna på ett sätt som inte är förenligt med tillämpliga
          lagar eller förordningar.
          <br />
          <br />
          · Engagera sig i otillåten inramning av eller länkning till
          tjänsterna.
          <br />
          <br />
          · Ladda upp eller överföra (eller försök att ladda upp eller överföra)
          virus, trojanska hästar eller annat material, inklusive överdriven
          användning av versaler och spam (kontinuerlig postning av upprepad
          text), som stör någon parts oavbrutna användning och åtnjutande av
          tjänsterna eller modifierar, försämrar, stör, ändrar eller stör
          användningen, funktionerna, driften eller underhållet av tjänsterna.
          <br />
          <br />
          · Engagera sig i all automatiserad användning av systemet, till
          exempel att använda skript för att skicka kommentarer eller
          meddelanden, eller använda någon datautvinning, robotar eller liknande
          verktyg för datainsamling och utvinning.
          <br />
          <br />
          · Ta bort meddelandet om upphovsrätt eller annan äganderätt från
          innehållet.
          <br />
          <br />
          · Försök att utge sig för en annan användare eller person eller
          använda en annan användares användarnamn.
          <br />
          <br />
          · Ladda upp eller överför (eller försök att ladda upp eller överföra)
          allt material som fungerar som en passiv eller aktiv
          informationsinsamling eller överföringsmekanism, inklusive utan
          begränsning, tydliga grafikutbytesformat ('gifs'), 1x1 pixlar,
          webbuggar, cookies, eller andra liknande enheter (ibland kallade
          "spionprogram" eller "passiva insamlingsmekanismer" eller "pcms").
          <br />
          <br />
          · Störa eller skapa en otillbörlig börda på Tjänsterna eller de
          nätverk eller tjänster som är anslutna till Tjänsterna.
          <br />
          <br />
          · Trakassera, irritera, skrämma eller hota någon av våra anställda
          eller agenter som är engagerade i att tillhandahålla någon del av
          tjänsterna till dig.
          <br />
          <br />
          · Försök att kringgå alla åtgärder av tjänsterna som är utformade för
          att förhindra eller begränsa åtkomst till tjänsterna, eller någon del
          av tjänsterna.
          <br />
          <br />
          · Kopiera eller anpassa Tjänsternas programvara, inklusive men inte
          begränsat till Flash, PHP, HTML, JavaScript eller annan kod.
          <br />
          <br />
          · Förutom vad som är tillåtet enligt tillämplig lag, dechiffrera,
          dekompilera, demontera eller bakåtkonstruera någon av programvaran som
          utgör eller på något sätt utgör en del av tjänsterna
          <br />
          <br />
          · Förutom när det kan vara resultatet av standardanvändning av
          sökmotorer eller webbläsare, använd, lansera, utveckla eller
          distribuera alla automatiserade system, inklusive utan begränsning,
          spindel, robot, fuskverktyg, skrapa eller offlineläsare som får
          åtkomst till tjänsterna, eller använda eller starta något obehörigt
          skript eller annan programvara.
          <br />
          <br />
          · Använd en inköpsagent eller inköpsagent för att göra inköp på
          Tjänsterna.
          <br />
          <br />
          · Göra någon obehörig användning av Tjänsterna, inklusive att samla in
          användarnamn och/eller e-postadresser till användare på elektronisk
          väg eller på annat sätt i syfte att skicka oönskad e-post, eller skapa
          användarkonton på automatiserade sätt eller under falska
          förevändningar.
          <br />
          <br />
          · Använd tjänsterna som en del av alla försök att konkurrera med oss
          eller på annat sätt använda tjänsterna och/eller innehållet för någon
          intäktsgenererande strävan eller kommersiellt företag.
          <br />
          <br />
          · Använd tjänsterna för att annonsera eller erbjuda att sälja varor
          och tjänster.
          <br />
          <br />· Sälja eller på annat sätt överföra din profil.
        </p>
      </article>

      <article>
        <h2>
          <strong>6. ANVÄNDARGENERERADE BIDRAG</strong>
        </h2>
        <p>
          Tjänsterna erbjuder inte användare att skicka eller lägga upp
          innehåll.
        </p>
      </article>

      <article>
        <h2>
          <strong>7. BIDRAGSLICENS</strong>
        </h2>
        <p>
          Du och tjänsterna samtycker till att vi kan komma åt, lagra, bearbeta
          och använda all information och personlig information som du
          tillhandahåller enligt villkoren i integritetspolicyn och dina val
          (inklusive inställningar).
          <br />
          <br />
          Genom att skicka in förslag eller annan feedback angående tjänsterna
          samtycker du till att vi kan använda och dela sådan feedback för alla
          ändamål utan ersättning till dig.
        </p>
      </article>

      <article>
        <h2>
          <strong>8. HANTERING AV TJÄNSTER</strong>
        </h2>
        <p>
          Vi förbehåller oss rätten, men inte skyldigheten, att: (1) övervaka
          tjänsterna för brott mot dessa juridiska villkor; (2) vidta lämpliga
          rättsliga åtgärder mot någon som, efter vårt eget gottfinnande, bryter
          mot lagen eller dessa juridiska villkor, inklusive utan begränsning,
          rapportering av en sådan användare till brottsbekämpande myndigheter;
          (3) efter eget gottfinnande och utan begränsning, vägra, begränsa
          åtkomst till, begränsa tillgängligheten till eller inaktivera (i den
          utsträckning det är tekniskt möjligt) något av dina bidrag eller någon
          del därav; (4) att efter eget gottfinnande och utan begränsning,
          meddelande eller ansvar ta bort från tjänsterna eller på annat sätt
          inaktivera alla filer och innehåll som är överdrivet stora eller på
          något sätt betungande för våra system; och (5) på annat sätt hantera
          tjänsterna på ett sätt som är utformat för att skydda våra rättigheter
          och egendom och för att underlätta en korrekt funktion av tjänsterna.
        </p>
      </article>

      <article>
        <h2>
          <strong>9. SEKRETESSPOLICY</strong>
        </h2>
        <p>
          Vi bryr oss om datasekretess och säkerhet. Läs vår integritetspolicy:{" "}
          <br />
          https://www.klimra.com/privacy-policy. Genom att använda tjänsterna
          samtycker du till att vara bunden av vår integritetspolicy, som är
          införlivad i dessa juridiska villkor. Observera att tjänsterna är värd
          i Sverige. Om du får tillgång till tjänsterna från någon annan region
          i världen med lagar eller andra krav som reglerar insamling,
          användning eller avslöjande av personuppgifter som skiljer sig från
          tillämpliga lagar i Sverige, överför du genom din fortsatta användning
          av tjänsterna dina data till Sverige , och du samtycker uttryckligen
          till att dina uppgifter överförs till och behandlas i Sverige.
        </p>
      </article>

      <article>
        <h2>
          <strong>10. TID OCH UPPSÄGNING</strong>
        </h2>
        <p>
          Dessa juridiska villkor ska förbli i full kraft och verkan medan du
          använder tjänsterna. UTAN ATT BEGRÄNSA NÅGRA ANDRA BESTÄMMELSER I
          DESSA JURIDISKA VILLKOR, FÖRBEHÅLLER VI RÄTTEN ATT, EFTER VÅRT ENDA
          GODKÄNNANDE OCH UTAN MEDDELANDE ELLER ANSVAR, NEKA TILLGÅNG TILL OCH
          ANVÄNDNING AV TJÄNSTERNA (INKLUSIVE BLOCKERING AV VISSA VISSA
          UPPLYSNINGAR FÖR EN IP-ADRESSON), FÖR EN IP-ADRESSON. INGEN ANLEDNING,
          INKLUSIVE UTAN BEGRÄNSNING FÖR BROTT MOT NÅGON REPRESENTATION, GARANTI
          ELLER FÖRBUND SOM FINNS I DESSA JURIDISKA VILLKOR ELLER NÅGON
          TILLÄMPLIG LAGAR ELLER FÖRESKRIFTER. VI KAN AVSLUTA DIN ANVÄNDNING
          ELLER DELTAGANDE I TJÄNSTERNA ELLER RADERA DITT KONTO OCH EVENTUELLT
          INNEHÅLL ELLER INFORMATION SOM DU LAGT UPP NÄR SOM HELST, UTAN
          VARNING, EFTER VÅRT ENDA GODKÄNNANDE.
          <br />
          <br />
          Om vi avslutar eller stänger av ditt konto av någon anledning, är du
          förbjuden att registrera och skapa ett nytt konto under ditt namn, ett
          falskt eller lånat namn, eller namnet på någon tredje part, även om du
          kan agera på uppdrag av tredje part. Förutom att avsluta eller stänga
          av ditt konto förbehåller vi oss rätten att vidta lämpliga rättsliga
          åtgärder, inklusive men inte begränsat till civilrättsliga,
          straffrättsliga och föreläggande.
        </p>
      </article>

      <article>
        <h2>
          <strong>11. MODIFIKATIONER OCH AVBROTT</strong>
        </h2>
        <p>
          Vi förbehåller oss rätten att ändra, modifiera eller ta bort
          innehållet i tjänsterna när som helst eller av någon anledning efter
          eget gottfinnande utan föregående meddelande. Vi har dock ingen
          skyldighet att uppdatera någon information om våra tjänster. Vi kommer
          inte att vara ansvariga gentemot dig eller någon tredje part för någon
          ändring, prisändring, avstängning eller avbrytande av tjänsterna.
          <br />
          <br />
          Vi kan inte garantera att tjänsterna alltid är tillgängliga. Vi kan
          uppleva hårdvara, mjukvara eller andra problem eller behöva utföra
          underhåll relaterat till tjänsterna, vilket leder till avbrott,
          förseningar eller fel. Vi förbehåller oss rätten att ändra, revidera,
          uppdatera, stänga av, avbryta eller på annat sätt modifiera tjänsterna
          när som helst eller av vilken anledning som helst utan att meddela
          dig. Du samtycker till att vi inte har något som helst ansvar för
          någon förlust, skada eller olägenhet som orsakats av din oförmåga att
          komma åt eller använda tjänsterna under någon driftstopp eller avbrott
          av tjänsterna. Ingenting i dessa juridiska villkor kommer att tolkas
          för att förplikta oss att underhålla och stödja tjänsterna eller att
          tillhandahålla några korrigeringar, uppdateringar eller utgåvor i
          samband därmed.
        </p>
      </article>

      <article>
        <h2>
          <strong>12. GÄLLANDE LAG</strong>
        </h2>
        <p>
          Dessa juridiska villkor styrs av och tolkas i enlighet med svensk lag,
          och användningen av FN:s avtalskonvention för internationella
          försäljningar av varor är uttryckligen utesluten. Om din vanliga
          vistelseort är i EU och du är konsument, har du dessutom det skydd som
          du får av obligatoriska bestämmelser i lagen i ditt land för att
          bosätta dig. Klimra AB och du är båda överens om att underkasta dig
          den icke-exklusiva jurisdiktionen för Stockholms domstolar, vilket
          innebär att du kan göra anspråk på att försvara dina
          konsumentskyddsrättigheter med avseende på dessa juridiska villkor i
          Sverige, eller i det EU-land där du bor.
        </p>
      </article>

      <article>
        <h2>
          <strong>13. TVISTLÖSNING</strong>
        </h2>
        <p>
          Europeiska kommissionen tillhandahåller en onlineplattform för
          tvistlösning som du kan komma åt. Om du vill uppmärksamma oss på detta
          ämne, vänligen kontakta oss.
        </p>
      </article>

      <article>
        <h2>
          <strong>14. RÄTTELSER</strong>
        </h2>
        <p>
          Det kan finnas information om Tjänsterna som innehåller typografiska
          fel, felaktigheter eller utelämnanden, inklusive beskrivningar,
          priser, tillgänglighet och diverse annan information. Vi förbehåller
          oss rätten att korrigera eventuella fel, felaktigheter eller
          utelämnanden och att ändra eller uppdatera informationen på Tjänsterna
          när som helst utan föregående meddelande.
        </p>
      </article>

      <article>
        <h2>
          <strong>15. DISCLAIMER</strong>
        </h2>
        <p>
          TJÄNSTERNA TILLHANDAHÅLLS I BEFINTLIGT SKICK OCH SOM ÄR TILLGÄNGLIG.
          DU GODKÄNNER ATT DIN ANVÄNDNING AV TJÄNSTERNA KOMMER PÅ DIN EGEN RISK.
          I DEN FULLSTÄNDIGA UTSTRÄCKNING SOM TILLÅTS AV LAGEN, FRÅNSÄGER VI SIG
          ALLA GARANTIER, UTTRYCKTA ELLER UNDERFÖRSTÅDDA, I SAMBAND MED
          TJÄNSTERNA OCH DIN ANVÄNDNING AV DESSA, INKLUSIVE, UTAN BEGRÄNSNING,
          UNDERFÖRSTÅDDA GARANTIER FÖR EJ, ELLER MÖJLIGHET INTRÄDE. VI GER INGA
          GARANTIER ELLER UTSKRIFTER OM RIKTIGHETEN ELLER FULLSTÄNDIGHETEN I
          TJÄNSTERNAS INNEHÅLL ELLER INNEHÅLLET I NÅGON WEBBPLATS ELLER MOBILA
          APPLIKATIONER LÄNKAD TILL TJÄNSTERNA OCH VI TAR INGET ANSVAR FÖR (1)
          FEL, MISSTAG ELLER FELAKTIGEHTER AV INNEHÅLLSFÖRESKRIFTER OCH
          MATERIAL, (2) PERSONSKADA ELLER EGENDOMSSKADA, AV NÅGOT NATUR SOM
          RESULTAT FRÅN DIN ÅTKOMST TILL OCH ANVÄNDNING AV TJÄNSTERNA,(3)
          EVENTUELL ICKE AUKTORISERAD ÅTKOMST TILL ELLER ANVÄNDNING AV VÅR SÄKRA
          SERVERS OCH/ELLER PERSONLIG INFORMATION OCH/ELLER FINANCIELL
          INFORMATION LAGRAD DÄRI, (4) EVENTUELLA AVBROTT ELLER UPPHÖRANDE AV
          SÄNDNING TILL ELLER FRÅN TJÄNSTERNA, (5) EVENTUELLA BUGGAR, VIRUS,
          TROJANSKA HÄSTAR ELLER LIKNANDE SOM KAN ÖVERFÖRAS TILL ELLER GENOM
          NÅGON AV TJÄNSTERNA AV NÅGON TREDJE PART. (6) EVENTUELLA FEL ELLER
          UNDERLÄMNINGAR I NÅGOT INNEHÅLL OCH MATERIAL ELLER FÖR NÅGON FÖRLUST
          ELLER SKADA AV NÅGOT SLAG SOM UPPFÖRS SOM RESULTAT AV ANVÄNDNING AV
          NÅGOT INNEHÅLL SOM UPPLAGTS, ÖVERFÖRTS ELLER PÅ ANNAT VIS GJORTS
          TILLGÄNGLIGT VIA TJÄNSTEN. VI VARKEN MOTIVERAR, STÖDJER, GARANTERAR
          ELLER ANTAR NÅGOT ANSVAR FÖR NÅGON PRODUKT ELLER TJÄNST SOM ANNONSERAS
          ELLER ERBJUDS AV TREDJE PART GENOM TJÄNSTERNA, NÅGON HYPERLÄNKAD
          WEBBPLATS ELLER NÅGON ANNAN WEBBPLATS, OCH VI SKA INTE VARA PART TILL
          ELLER PÅ NÅGOT SÄTT ANSVARIG FÖR ÖVERVAKNING AV ALLA TRANSAKTIONER
          MELLAN DIG OCH TREDJEPARTSLEVERANTÖRER AV PRODUKTER ELLER TJÄNSTER.
          SOM VID KÖP AV EN PRODUKT ELLER TJÄNST I NÅGOT MEDEL ELLER I NÅGON
          MILJÖ BÖR DU ANVÄNDA DITT BÄSTA BEDÖMMANDE OCH IAKTA FÖRSIKTIGHET DÄR
          LÄMPLIGT.
        </p>
      </article>

      <article>
        <h2>
          <strong>16. ANSVARSBEGRÄNSNINGAR</strong>
        </h2>
        <p>
          UNDER INGA OMSTÄNDIGHETER KOMMER VI ELLER VÅRA DIREKTÖRER, ANSTÄLLDA
          ELLER AGENTER HÅLLAS ANSVARIGA MOT DIG ELLER NÅGON TREDJE PART FÖR
          NÅGON DIREKT, INDIREKTA FÖLJD, EXEMPEL, OAVSIKTLIGA, SPECIELLA ELLER
          PUNITIVA SKADOR, INKLUSIVE OM/ELLER FÖRLUST, ANDRA SKADOR SOM UPPSTÅR
          FRÅN DIN ANVÄNDNING AV TJÄNSTERNA, ÄVEN OM VI HAR UPPRÄTTATS OM
          MÖJLIGHETEN FÖR SÅDANA SKADA.
        </p>
      </article>

      <article>
        <h2>
          <strong>17. ERSÄTTNING</strong>
        </h2>
        <p>
          Du samtycker till att försvara, gottgöra och hålla oss oskadliga,
          inklusive våra dotterbolag och alla våra respektive tjänstemän,
          agenter, partners och anställda, från och mot förlust, skada, ansvar,
          anspråk eller krav, inklusive rimliga advokater ' avgifter och
          utgifter, gjorda av tredje part på grund av eller till följd av: (1)
          användning av tjänsterna; (2) brott mot dessa juridiska villkor: (3)
          alla brott mot dina utfästelser och garantier som anges i dessa
          juridiska villkor; (4) din kränkning av en tredje parts rättigheter,
          inklusive men inte begränsat till immateriella rättigheter; eller (5)
          någon uppenbar skadlig handling mot någon annan användare av
          Tjänsterna som du anslutit till via Tjänsterna. Trots det föregående
          förbehåller vi oss rätten att, på din bekostnad, överta det exklusiva
          försvaret och kontrollen av alla frågor som du är skyldig att hålla
          oss skadeslösa för. Och du samtycker till att samarbeta, på din
          bekostnad, med vårt försvar av sådana anspråk. Vi kommer att vidta
          rimliga ansträngningar för att meddela dig om sådana anspråk, åtgärder
          eller förfaranden som är föremål för denna gottgörelse när vi blir
          medvetna om det.
        </p>
      </article>

      <article>
        <h2>
          <strong>18. ANVÄNDARDATA</strong>
        </h2>
        <p>
          Vi kommer att behålla vissa uppgifter som du överför till Tjänsterna i
          syfte att hantera Tjänsternas prestanda, såväl som uppgifter
          relaterade till din användning av Tjänsterna. Även om vi utför
          regelbundna rutinmässiga säkerhetskopieringar av data, är du ensam
          ansvarig för all data som du överför eller som hänför sig till någon
          aktivitet du har gjort med hjälp av tjänsterna. Du samtycker till att
          vi inte ska ha något ansvar gentemot dig för någon förlust eller
          korruption av sådan data, och du avsäger dig härmed all rätt att vidta
          åtgärder mot oss som uppstår till följd av sådan förlust eller
          korruption av sådan data.
        </p>
      </article>

      <article>
        <h2>
          <strong>
            19. ELEKTRONISK KOMMUNIKATION, TRANSAKTIONER OCH SIGNATER
          </strong>
        </h2>
        <p>
          Att besöka tjänsterna, skicka e-post till oss och fylla i
          onlineformulär utgör elektronisk kommunikation. Du samtycker till att
          ta emot elektronisk kommunikation och du samtycker till att alla
          avtal, meddelanden, avslöjanden och annan kommunikation som vi
          tillhandahåller dig elektroniskt, via e-post och på tjänsterna,
          uppfyller alla juridiska krav på att sådan kommunikation ska vara
          skriftlig. DU GODKÄNNER HÄRMED ANVÄNDNING AV ELEKTRONISKA SIGNATURER,
          KONTRAKT, BESTÄLLNINGAR OCH ANDRA PEKTORER OCH TILL ELEKTRONISK
          LEVERANS AV MEDDELANDEN, POLICIER OCH REGISTRERING AV TRANSAKTIONER
          SOM INITIERAS ELLER SLUTFÖRS AV OSS ELLER VIA TJÄNSTER. Du avsäger dig
          härmed alla rättigheter eller krav enligt lagar, förordningar, regler,
          eller andra lagar i någon jurisdiktion som kräver en originalsignatur
          eller leverans eller lagring av icke-elektroniska register, eller till
          betalningar eller beviljande av krediter på något annat sätt än
          elektroniska medel.
        </p>
      </article>

      <article>
        <h2>
          <strong>20. SMS TEXT MEDDELANDE</strong>
        </h2>
        <p>
          <strong>Välja bort</strong>
        </p>
        <p>
          Om du vid något tillfälle vill sluta ta emot sms från oss, vänligen
          radera ditt konto eller ring oss på 0761608084
        </p>
        <p>
          <strong>Meddelande och datahastigheter</strong>
        </p>
        <p>
          Var medveten om att meddelande- och dataavgifter kan gälla för alla
          SMS-meddelanden som skickas eller tas emot. Priserna bestäms av din
          operatör och specifikationerna för ditt mobilabonnemang.
        </p>
        <p>
          <strong>Stöd</strong>
        </p>
        <p>
          Om du har några frågor eller behöver hjälp angående vår
          SMS-kommunikation, vänligen maila oss på contact@klimra.com eller ring
          på 0761608084
        </p>
      </article>

      <article>
        <h2>
          <strong>21. ÖVRIGT</strong>
        </h2>
        <p>
          Dessa juridiska villkor och eventuella policyer eller driftregler som
          publicerats av oss på tjänsterna eller med avseende till tjänsterna
          utgör hela avtalet och förståelsen mellan dig och oss. Vår
          underlåtenhet att utöva eller upprätthålla någon rättighet eller
          bestämmelse i dessa juridiska villkor ska inte fungera som ett
          avstående från sådan rättighet eller bestämmelse. Dessa juridiska
          villkor fungerar i den utsträckning som är tillåten enligt lag. Vi kan
          överlåta någon eller alla av våra rättigheter och skyldigheter till
          andra när som helst. Vi ska inte vara ansvariga eller skydliga för
          någon förlust, skada, försening eller underlåtenhet att agera som
          orsakats av någon orsak utanför vår rimliga kontroll. Om någon
          bestämmelse eller del av en bestämmelse i dessa juridiska villkor
          fastställs vara olaglig, ogiltig, eller omöjlig att verkställa, den
          bestämmelsen eller en del av bestämmelsen anses vara avskiljbar från
          dessa juridiska villkor och påverkar inte giltigheten och
          verkställbarheten av eventuella återstående bestämmelser. Det finns
          inget joint venture, partnerskap, anställning eller agenturförhållande
          som skapats mellan dig och oss som ett resultat av dessa juridiska
          villkor eller användning av tjänsterna. Du samtycker till att dessa
          juridiska villkor inte kommer att tolkas mot oss i kraft av att ha
          utarbetat dem. Du avsäger dig härmed alla försvar du kan ha baserat på
          den elektroniska formen av dessa juridiska villkor och avsaknaden av
          undertecknande av parterna för att verkställa dessa juridiska villkor.
        </p>
      </article>

      <article>
        <h2>
          <strong>22. KONTAKTA OSS</strong>
        </h2>
        <p>
          För att lösa ett klagomål angående tjänsterna eller för att få
          ytterligare information om användningen av tjänsterna, vänligen
          kontakta oss på:
          <br />
          <br />
          Klimra AB
          <br />
          Östermalmsgatan 26A
          <br />
          stockholm, Stockholm 11426
          <br />
          Sverige
          <br />
          Telefon: 0761608084
          <br />
          contact@klimra.com
        </p>
      </article>

      <article>
        <h2>
          <strong></strong>
        </h2>
        <p></p>
      </article>

      <article>
        <h2>
          <strong></strong>
        </h2>
        <p></p>
      </article>
    </section>
  );
};
