import { faHandshakeAngle } from "@fortawesome/free-solid-svg-icons";
import { Banner } from "Components/Common/Banner";
import React from "react";
import banner from "assets/images/my-rights/my-rights.jpg";

interface Props {}

export const RightsBannerSection: React.FC<Props> = (): JSX.Element => {

  return (
    <Banner
      title="Var ditt tåg i tid?"
      subTitle="Om ditt tåg var försenat kan du ha rätt till kompensation. Vi förstår
        hur frustrerande och komplicerat denna process kan vara, och vi är här
        för att ta hand om det åt dig. Låt oss ta bort besväret med att kräva
        det du har rätt till"
      bannerFooter={
        {
          icon: faHandshakeAngle,
          text: "Engagerade i att se till att du får det som du har rätt till",
        }
      }
      image={banner}
      spacing={false}
    />
  );
};
