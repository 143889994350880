import { createUseStyles, Theme } from "react-jss";
import React from "react";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "70%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
      marginTop: "20px"
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {
  style?: React.CSSProperties;
}

export const CookiesInformation: React.FC<Props> = ({style}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection} style={style}>
      <article>
        <h1>Cookies For Klimra</h1>
        <h2>Kära användare,</h2>
        <p>
          Denna cookiepolicy beskriver hur Klimra AB, organisationsnummer
          559490-0986 med registrerad adress på Drömstigen 41, använder cookies
          på https://www.klimra.com.
        </p>

        <h2>1. VAD ÄR EN COOKIE?</h2>
        <p>
          En cookie är en liten bit data som skickas från en Webbplats och
          lagras i en användares webbläsare, mobiltelefon eller annan enhet
          medan användaren surfar på den Webbplatsen. En cookie kan hjälpa
          Webbplatsleverantören att känna igen din enhet och/eller webbläsare
          nästa gång du besöker Webbplatsen, ge dig tillgång till vissa
          funktioner på Webbplatsen och/eller registrera dina surfmönster. Det
          finns andra tekniker som pixeltaggar, webbuggar, webblagring och andra
          liknande filer och tekniker som kan ha samma funktioner som cookies. I
          denna cookiepolicy använder vi termen "cookies" för cookies och alla
          liknande tekniker.
        </p>

        <h2>2. VILKA TYPER AV COOKIES ANVÄNDER VI?</h2>
        <p>Vi använder följande cookies:</p>
        <ul>
          <li>
            <strong>Strikt nödvändiga cookies</strong> - Dessa cookies är strikt
            nödvändiga för att du ska kunna röra dig på webbplatsen och använda
            dess funktioner. Webbplatsen kan inte fungera utan dessa cookies.
            Strikt nödvändiga cookies kräver inte ditt samtycke eftersom de
            möjliggör tjänster som du specifikt har bett om och som inte kan
            tillhandahållas på annat sätt.
          </li>
          <li>
            <strong>Funktionalitetscookies</strong> - Dessa cookies gör det
            möjligt för webbplatsen att spara uppgifter som du har lämnat för
            att kunna erbjuda en bättre upplevelse, t.ex. att webbplatsen kan
            komma ihåg val som du gör, t.ex. ditt användarnamn, språk eller den
            region du befinner dig i. Vissa delar av webbplatsen kanske inte
            fungerar korrekt utan dessa cookies.
          </li>
          <li>
            <strong>Prestanda-cookies</strong> - Dessa cookies används för att
            vi ska kunna analysera webbplatsens prestanda och utformning och för
            att upptäcka fel. Dessa cookies gör det också möjligt för oss att
            känna igen att du har besökt webbplatsen tidigare, vilka webbplatser
            på webbplatsen som besökare besöker oftast och hur mycket tid
            besökare tillbringar på webbplatsen. Syftet med dessa cookies är
            enbart att förbättra webbplatsens prestanda och din upplevelse.
          </li>
        </ul>

        <h2>3. Information om våra cookies</h2>
        <p>
          Vi använder följande cookies för de ändamål som anges nedan:
        </p>
        <ul>
          <li>
            <strong>Typ av cookie:</strong>
            <ul>
              <li>
                <strong>Cookies för session</strong> - Tillfälliga cookies som
                upphör att gälla när du stänger din webbläsare. Dessa används
                för att upprätthålla din session medan du navigerar genom vår
                webbplats.
              </li>
              <li>
                <strong>Permanenta cookies</strong> - Cookies som ligger kvar på
                en användares enhet under en viss tid eller tills de raderas
                manuellt.
              </li>
              <li>
                <strong>Cookies från första part</strong> - Dessa cookies sparas
                av vår webbplats och lagrar dina preferenser och inloggningsuppgifter
                för att ge dig en smidig upplevelse.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          Utöver cookies använder Klimra också pixlar. Pixlar är kodavsnitt som
          gör det möjligt för Relivo att samla in information om
          webbplatsbesökare och vilka åtgärder de vidtar under sitt besök.
          Informationen används i syfte att förbättra webbplatsen och skapa en
          bättre upplevelse för besökarna.
        </p>

        <h2>4. DITT SAMTYCKE TILL COOKIES</h2>
        <p>
          Genom att klicka på "Jag accepterar" på vår banner om integritet,
          cookies och användarvillkor när du skapar ett konto, samtycker du till
          användningen av cookies.
        </p>
        <p>
          Den personuppgiftsbehandling som sker genom cookies baseras på ditt
          samtycke som rättslig grund för behandlingen. Detta innebär att du kan
          välja att tillåta eller neka vissa eller alla cookies, med undantag
          för strikt nödvändiga cookies. De flesta webbläsare ger dig också
          möjlighet att hantera dina cookie-inställningar. Du kan ställa in din
          webbläsare så att den vägrar ta emot cookies eller raderar vissa
          cookies. I allmänhet bör du också kunna hantera liknande teknik genom
          att använda din webbläsares inställningar.
        </p>
        <p>
          Du kan granska eller ta bort cookies som redan har ställts in via din
          webbläsare. Hur du kan göra detta skiljer sig vanligtvis från en
          webbläsare till en annan. De flesta webbläsare har publicerat
          instruktioner om hur man hanterar cookies.
        </p>
        <p>
          Eftersom cookies alltid ställs in på den enhet och webbläsare som du
          använder för att besöka webbplatsen måste du separat hantera cookies
          på alla olika enheter och webbläsare som du använder för att besöka
          webbplatsen.
        </p>
        <p>
          Observera att om du väljer att blockera cookies kan Webbplatsens
          funktionalitet påverkas.
        </p>

        <h2>5. PERSONUPPGIFTSBEHANDLING SOM SKER VIA COOKIES</h2>
        <p>
          I enlighet med tabellerna ovan (se punkterna 3 och 4) har vi följande
          ändamål och rättsliga grunder för den personuppgiftsbehandling som
          sker via cookies:
        </p>
        <p>
          a) Prestanda och funktionalitet. Vi analyserar webbplatsens prestanda
          och utformning samt för att upptäcka fel. Personuppgiftsbehandling som
          sker inom ramen för dessa ändamål baseras på laglig grund.
        </p>
        <p>
          Om du vill läsa mer om dina rättigheter eller hur vi behandlar dina
          personuppgifter, vänligen läs vår{" "}
          <a href="https://www.klimra.com/privacy-policy" aria-label={`privacy-policy`}>Integritetspolicy</a>.
        </p>

        <h2>6. KONTAKTA OSS</h2>
        <p>
          Om du har några frågor eller kommentarer om vår användning av cookies,
          vänligen kontakta <a href="mailto:contact@klimra.com" aria-label="email-klimra">contact@klimra.com</a>.
        </p>
      </article>
    </section>
  );
};
