import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { ROUTES } from "constants/routes";
import { InfoSlideWithImage } from "Components/Common/InfoSlideWithImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import image from "assets/images/settings/settings-alone.jpg";

interface Props {}

export const BenefitsSectionSettings: React.FC<Props> = ({}): JSX.Element => {
  return (
    <InfoSlideWithImage
      title="Klimra - Din Partner för Snabb Ersättning"
      imageAlt="Person smiling on a train with their phone in hand."
      imageSrc={image}
    >
      <p style={{ marginTop: "20px", marginBottom: "50px" }}>
      Med Klimra slipper du krångla med försenade tåg och komplicerade regler. Vi tar hand om hela processen och ser till att du får rätt ersättning snabbt och enkelt. Luta dig tillbaka, vi har allt under kontroll.
      </p>
     
      <AnchorButton path={ROUTES.REGISTER}>
        Kräv kompensation <FontAwesomeIcon icon={faArrowRight} />
      </AnchorButton>
    </InfoSlideWithImage>
  );
};
