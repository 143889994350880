import { createUseStyles, Theme } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Space from "Components/Common/Space";
import KlimraMap from "assets/images/about-us/klimra-map.png";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  card: {
    backgroundColor: theme.colors.backgroundPrimary,
    boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    padding: '2rem',
    maxWidth: '900px',
    width: '80%',
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: '2rem',
    '@media (max-width: 700px)': {
      marginLeft: '0',
      marginTop: '0',
    },
  },
  text: {
    width: '100%',
    maxWidth: "700px",
    textAlign: 'left',
    fontSize: '1.2rem',
    marginTop: '0.2rem',
    marginLeft: '2rem',
    '@media (max-width: 700px)': {
      marginLeft: '0',
    }
  },
  list: {
    fontSize: '1.5rem',
    '@media (max-width: 700px)': {
      marginLeft: '0',
      paddingLeft: '0',
    }
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '2rem',
    fontSize: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '1rem',
  },
  contactInfo: {
    fontSize: '0.875rem',
    '@media (min-width: 768px)': {
      fontSize: '1.125rem',
    },
  },
  image: {
    width: '100%',
    borderRadius: '12px',
  },
}));

export const ContactUsCard: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <h4 className={classes.header}>Kontakta oss</h4>
        <p className={classes.text}>
          Kontakta oss med eventuella frågor om kompensation för tågförseningar, ditt krav, eller om du behöver hjälp med relaterade ärenden.
        </p>
        <Space type="medium" />
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <FontAwesomeIcon className={classes.icon} icon={faLocationDot} />
            <h6 className={classes.contactInfo}>Östermalmsgatan 26A, Stockholm</h6>
          </li>
          <li className={classes.listItem}>
            <FontAwesomeIcon className={classes.icon} icon={faEnvelope} />
            <h6 className={classes.contactInfo}>contact@klimra.com</h6>
          </li>
          <li className={classes.listItem}>
            <FontAwesomeIcon className={classes.icon} icon={faPhone} />
            <h6 className={classes.contactInfo}>+46 761993236</h6>
          </li>
        </ul>
        <Space type="medium" />
        <a target="_blank" href="https://www.google.com/maps/place/SSE+Business+Lab/@59.3439359,18.0693994,17z/data=!3m1!4b1!4m6!3m5!1s0x465f9d68adf19705:0xe09d2e33aaed484b!8m2!3d59.3439359!4d18.0693994!16s%2Fg%2F11c1tmlcy4?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D" aria-label={`contactImage`}>
          <img className={classes.image} src={KlimraMap} alt="Map of where klimra is" />
        </a>
      </div>
    </div>
  );
};
