import React from 'react';
import { createUseStyles, Theme } from 'react-jss';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Space from 'Components/Common/Space';
import Text from 'Components/Common/Text';
import { KlimraTheme } from 'styles/theme';
import { ROUTES } from 'constants/routes';
import sse from "assets/images/footer/SSE.png";
import logo from "assets/images/logo/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationPin, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';

const useStyles = createUseStyles((theme: Theme) => ({
  footer: {
    backgroundColor: theme.colors.secondary,
    padding: '60px 5vw',
    position: 'relative',
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    color: 'black',
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      gap: '40px',
    },
    maxWidth: '1400px',
    margin: 'auto',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    maxWidth: '500px',
  },
  image: {
    width: '80%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: 'fit-content',
  },
  linkContainer: {
    margin: '0px',
    cursor: 'pointer',
    display: 'flex',
    gap: '20px',
  },
  additionalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flex: 0.7,
    '@media (min-width: 1000px)': {
      marginLeft: "50px"
    }
  },
  socialLinks: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 420px)': {
      gap: '10px',
    },
  },
  orgInfo: {
    color: theme.colors.primary,
    margin: '0px',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  sseImageBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    flex: 0.6,
    justifyContent: 'center',
    '@media (max-width: 1000px)': {
      position: 'absolute',
      bottom: '40px',
      right: '5vw',
    },
  },
  sseImage: {
    maxWidth: '200px',
    maxHeight: '100%',
    objectFit: 'contain',
    '@media (max-width: 1000px)': {
      maxWidth: 'min(30vw, 200px)',
    },
  },
  linkText: {
    margin: '0px',
    cursor: 'pointer',
  },
  linkTextFlex: {
    display: 'flex',
    margin: '0px',
    cursor: 'pointer',
  },
  bottomBox: {
    backgroundColor: theme.colors.secondaryComplement,
    display: 'flex',
    padding: '40px 5vw',
  },
  noUnderline: {
    textDecoration: 'none',
  }
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box>
      <footer className={classes.footer}>
        <Box className={classes.footerContent}>
          <Box className={classes.column}>
            <img src={logo} className={classes.image} alt="Logo" />
            <Space type="large" />
            <Text
              text="Vår avancerade teknik säkerställer att du får den ersättningen du förtjänar utan huvudvärk."
              type="h7.500"
              sx={{ lineHeight: '191%' }}
            />
          </Box>
          <Box>
            <Box className={classes.contactInfo}>
              <Text text="Nå oss" type="h6.600" />
              <a href="tel:+46761993236" className={classes.noUnderline} aria-label={`klimra-number`}>
                <div className={classes.linkContainer}>
                  <FontAwesomeIcon icon={faPhone} color={"#000000"} />
                  <Text text="+46 761993236" type="h7.400" />
                </div>
              </a>
              <a href="mailto:contact@klimra.com" className={classes.noUnderline} aria-label={`mail-klimra`}>
                <div className={classes.linkContainer}>
                  <FontAwesomeIcon icon={faEnvelope} color={"#000000"} />
                  <Text text="contact@klimra.com" type="h7.400" />
                </div>
              </a>
              <a href="https://maps.app.goo.gl/arMpSG94iix9i4zJ7" target="_blank" rel="noopener noreferrer" className={classes.noUnderline} aria-label={`klimra-maps`}>
                <div className={classes.linkContainer}>
                  <FontAwesomeIcon icon={faLocationPin} color={"#000000"} />
                  <Text text="Östermalmsgatan 26A, Stockholm" type="h7.400" />
                </div>
              </a>
            </Box>
          </Box>
          <Box className={classes.additionalContent}>
            <Text text="Företag" type="h6.600" />
            <div
              className={classes.linkTextFlex}
              onClick={() => {
                navigate(ROUTES.ABOUT_US);
              }}
            >
              <Text text="Om oss" type="h7.400" />
            </div>
            <span
              className={classes.linkText}
              onClick={() => {
                navigate(ROUTES.ABOUT_US);
              }}
            >
              <Text text="Kontakta oss" type="h7.400" />
            </span>
            <span
              className={classes.linkText}
              onClick={() => {
                navigate(ROUTES.INVESTOR);
              }}
            >
              <Text text="Investor" type="h7.400" />
            </span>
            <Text text="Legalt" type="h6.600" />
            <span
              className={classes.linkText}
              onClick={() => {
                navigate(ROUTES.PRIVACY_POLICY);
              }}
            >
              <Text text="Integritetspolicy" type="h7.400" />
            </span>
            <span
              className={classes.linkText}
              onClick={() => {
                navigate(ROUTES.TERMS_OF_SERVICE);
              }}
            >
              <Text text="Användarvillkor" type="h7.400" />
            </span>
            <span
              className={classes.linkText}
              onClick={() => {
                navigate(ROUTES.COOKIES);
              }}
            >
              <Text text="Cookies" type="h7.400" />
            </span>
          </Box>
          <Box className={classes.sseImageBox}>
            <img src={sse} alt="Support" className={classes.sseImage} />
          </Box>
        </Box>
      </footer>
      <Box className={classes.bottomBox}>
        <Box className={classes.socialLinks}>
          <a href="https://www.instagram.com/klimra.se/" target="_blank" rel="noopener noreferrer" aria-label={`instagram`}>
            <FontAwesomeIcon icon={faInstagram} color={"#959595"} size='2x' />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61561592940008" target="_blank" rel="noopener noreferrer" aria-label={`facebook`}>
            <FontAwesomeIcon icon={faFacebook} color={"#959595"} size='2x' />
          </a>
          <a href="https://www.linkedin.com/company/klimra/" target="_blank" rel="noopener noreferrer" aria-label={`linkedin`}>
            <FontAwesomeIcon icon={faLinkedin} color={"#959595"} size='2x' />
          </a>
          <a href="https://www.tiktok.com/@klimra.com" target="_blank" rel="noopener noreferrer" aria-label={`tiktok`}>
            <FontAwesomeIcon icon={faTiktok} color={"#959595"} size='2x' />
          </a>
        </Box>
        <Box sx={{ flex: 1 }} />
        <Box className={classes.orgInfo}>
          <Text text="Org. nr. 559490-0986" type="h7.500" sx={{ color: KlimraTheme.colors.textGray }} />
        </Box>
      </Box>
    </Box>
  );
};
