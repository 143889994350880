import { createUseStyles, Theme } from "react-jss";
import { Banner } from "Components/Common/Banner";
import { faArrowRight, faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { InfoSection } from "Components/Misc/InfoSection";
import { Suspense } from "react";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { StatisticsSection } from "Components/Common/StatisticsSection";
import { ContactUsCard } from "Components/Misc/ContactUsCard";
import { ROUTES } from "constants/routes";
import { NewReviewSlideshow } from "Components/Misc/NewReviewSlideshow";
import SlideImage from "assets/images/home/person-smiling-on-train.jpg";
import { InfoSlideWithImage } from "Components/Common/InfoSlideWithImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner from "assets/images/about-us/about-us-banner.jpg";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    padding: '2rem',
    maxWidth: '100%',
    display: 'inline-block',
    '@media (min-width: 640px)': {
      padding: '5rem',
    },
  },
  image: {
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06)',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    maxWidth: '1000px',
  },
  text: {
    width: '80%',
    marginTop: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '700px',
  },
  sectionHeader: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1rem',
    '@media (min-width: 768px)': {
      textAlign: 'left',
    },
  },
  sectionTitle: {
    fontWeight: '600',
    fontSize: '2.5rem',
    marginTop: '1rem',
    '@media (min-width: 768px)': {
      textAlign: 'left',
    },
  },
  contactSection: {
    backgroundColor: theme.colors.primary,
    padding: '5rem 0',
    marginTop: '4rem',
    textAlign: 'center',
  },
  contactTitle: {
    fontSize: '2.5rem',
    fontWeight: '600',
    margin: '1rem 0 2.5rem 0',
    '@media (max-width: 500px)': {
      fontSize: '2rem',
    },
    '@media (max-width: 400px)': {
      fontSize: '1.5rem',
    },
  },
}));

export const AboutUs: React.FC = (): JSX.Element => {
  const classes = useStyles();

  const bannerContent = {
    title: "Om Oss",
    subTitle: "Historian bakom Klimra och varför vi finns",
    bannerFooter: {
      icon: faHandHoldingHeart,
      text: "Vi är här för att hjälpa dig",
    },
    image: banner,
    spacing: false,
  };

  const section = {
    title: "Vi är dedikerade till att göra din resa så bekväm som möjligt ",
    content: [
      `Klimra, ett svenskt företag i partnerskap med SSE Business Lab, hanterar skickligt dina kompensationsanspråk för tågförseningar och ser till att du får det du har rätt till inom 72h.`,
      `Trött på förseningar och krånglet med att kräva ersättning?`,
      `Du är inte ensam.`,
      `I Europa har nästan 20 % av alla tåg varit försenade i år!`,
      `Resenärer tvingas fylla i krångliga onlineformulär, ringa tågbolag och hålla reda på resedetaljer från veckor tillbaka, allt för att få den kompensation de har rätt till.
      Men det behöver inte vara så komplicerat. Vi har utvecklat en mjukvara som helt automatiserar processen att kräva kompensation. Vår avancerade teknik säkerställer att du får den ersättningen du förtjänar utan huvudvärk.`,
    ],
  };

  return (
    <div>
      <Banner {...bannerContent} />
      <Suspense>
        <InfoSection topTitle={"Om Klimra"} bg={"primary"} sections={[section]} />
      </Suspense>
      <Suspense>
        <InfoSlideWithImage
          title="Förenklad Kompensationsprocess vid Tågforseningar"
          imageSrc={SlideImage}
          imageAlt="Smiling person walking next to train looking at their phone"
          bg="backgroundPrimary"
          containerBg="backgroundLightGrey"
        >
          <p>
            Vi förstår hur frustrerande det kan vara att navigera i processen för kompensation vid tågforseningar. Vi känner också av den påverkan förseningar kan ha på dina planer och välbefinnande. Med vår lösning strävar vi efter att förenkla och snabba upp kompensationsanspråken, så att du snabbt och enkelt får den ersättning du har rätt till.
          </p>
          <br />
          <AnchorButton path={ROUTES.FAQ}>
            Så funkar det <FontAwesomeIcon icon={faArrowRight} />
          </AnchorButton>
        </InfoSlideWithImage>
      </Suspense>
      <Suspense>
        <StatisticsSection />
      </Suspense>
      <Suspense>
        <div className={classes.contentContainer}>
          <h2 className={classes.sectionHeader}>Hör det från våra kunder, inte bara oss</h2>
          <h1 className={classes.sectionTitle}>Recensioner</h1>
        </div>
        <NewReviewSlideshow />
      </Suspense>
      <Suspense>
        <div className={classes.contactSection}>
          <h1 className={classes.contactTitle}>Hur kan vi hjälpa dig?</h1>
          <ContactUsCard />
        </div>
      </Suspense>
    </div>
  );
};
