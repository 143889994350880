import { useScreenType } from "hooks/useScreenType";
import { PropsWithChildren } from "react";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  additionalQuestionsBlock: {
    backgroundColor: theme.colors.secondaryComplement,
    color: theme.colors.textSecondary,
    display: "flex",
    flexDirection: "row",
    maxWidth: "1000px",
    width: "100%",
    borderRadius: "10px",
    padding: "3rem",
    boxSizing: "border-box",
    alignItems: "center",
    "&.mobile": {
      flexDirection: "column",
      "& > *:not(:last-child)": {
        marginBottom: "1rem",
      },
      justifyContent: "center",
    },
    "& a": {
      margin: "0",
    },
    "& h1": {
      marginBottom: "10px",
      "@media (max-width: 1300px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 400px)": {
        fontSize: "1.1rem",
      },
    },
    "@media (max-width: 500px)": {
      padding: "2rem",
    },
  },
}));

interface Props {}

export const DarkBackgroundCard: React.FC<PropsWithChildren<Props>> = ({
  children,
}): JSX.Element => {
  const classes = useStyles();
  const { isMobile } = useScreenType();

  return (
    <div className={classes.container}>
      <div
        className={`${classes.additionalQuestionsBlock} ${
          isMobile ? "mobile" : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};
