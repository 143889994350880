import { createUseStyles, Theme } from "react-jss";

export const useButtonStyle = createUseStyles((theme: Theme) => ({
  anchorButton: {
    border: "none",
    borderRadius: "9999px",
    margin: (props: any) => props.centerAlign ? "0 auto" : undefined,
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.buttonPrimary,
    fontWeight: "bolder",
    padding: "1rem 2.5rem",
    filter: "drop-shadow(0 2px 2px #0006)",
    transition: "all 200ms ease",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.colors.buttonHover,
    },
  },
  anchorLoading: {
    display: "inline-flex",
    alignItems: "center",
    padding: "0.5rem 2.5rem",
  },
  icon: {
    marginLeft: "1rem",
  },
  iconLoading: {
    marginLeft: "0.1rem",
    width: "1rem",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
    "&:hover": {
      cursor: "not-allowed",
      backgroundColor: theme.colors.danger,
    },
  },
}));
