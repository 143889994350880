import { ClickableAccordion } from "Components/Common/ClickableAccordion";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  faqContainer: {
    "& h1": {
      margin: "0",
    },
    padding: "5rem 30vmin",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "@media (max-width: 1200px)": {
      padding: "2rem 20vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 850px)": {
      padding: "2rem 3vmin",
    },
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

interface Props {}

export const QuestionsAndAnswersSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  const questions: { title: string; content: string }[] = [
    {
      title: "Hur mycket kostar det?",
      content: "I förskott, ingenting. Klimra arbetar på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt krav inte lyckas. Om vi framgångsrikt driver din kompensation är vårt standardförfarande att tågbolaget överför pengarna direkt till dig, och därefter debiterar vi vår framgångsavgift på 20% på ditt betalkort.",
    },
    {
      title: "Varför välja oss?",
      content: "Att välja Klimra innebär att du slipper krångliga processer och stress. Vi gör kompensationen för tågförseningar automatisk, enkel och rättvis. Genom att hantera allt åt dig ser vi till att du får den ersättning du har rätt till, utan att du behöver lyfta ett finger. Vårt mål är att göra din resa så smidig som möjligt. Dessutom arbetar vi på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt krav inte lyckas.",
    },
    {
      title: "Vilka tågbolag och sträckor täcker ni?",
      content: "Vi täcker alla långdistanssträckor över 150 km där du reser med SJ tåg. Oavsett vilken destination du har inom detta intervall, kan du lita på att vi hanterar din kompensation om ditt tåg skulle bli försenat.",
    },
    {
      title: "Hur mycket ersättning har jag rätt till?",
      content: "För resor med tåg som går en längre sträcka (150 km eller längre) gäller Tågpassagerarförordningen. Vid 60-119 minuters försening har du rätt till 25% av biljettpriset. Vid 120 minuters försening har du rätt till 50% av biljettpriset.",
    },
    {
      title: "Hur hanterar ni mina personuppgifter?",
      content: "Din integritet är viktig för oss. Vi är därför angelägna om att de personuppgifter som samlas in om dig lagras och hanteras på ett tryggt och säkert sätt, och i enlighet med dataskyddslagstiftningen. Dina personuppgifter lagras bara så länge som det krävs för att uppfylla ändamålen med behandlingen, eller så länge som vi måste lagra dem enligt lag. Därefter raderas eller avidentifieras dina uppgifter på ett säkert sätt, så att de inte längre kan kopplas till dig.",
    },
    {
      title: "Kan jag använda Klimra trots att jag har månadskort på SJ?",
      content: "Ja, du kan använda Klimra även om du har ett månadskort på SJ. Klimra fungerar för alla typer av biljetter, inklusive månadskort."
    },
    {
      title: "Hur lång tid tar det innan jag får kompensation?",
      content: "Handläggningstiden för varje ärende varierar från fall till fall. De särskilda omständigheterna kring varje resa påverkar hur lång tid det tar att genomföra ditt ärende. En exakt specifikation är därför inte möjlig, men vi kommer alltid att hålla dig uppdaterad om statusen på ditt ärende.",
    },
    {
      title: "Kan var få en kompensation för en försening som hände innan jag hade ett konto på Klimra?",
      content: "Ja, vi kan hantera ärenden från upp till månader tillbaka. Du behöver bara tala om för oss att du inte har krävt kompensation för ärendet tidigare. Detta gör du enkelt genom ett knapptryck när du är inloggad på ditt konto.",
    },
  ];

  return (
    <div className={classes.faqContainer}>
      <h1>Frågor och svar</h1>
      <div className={classes.questionsContainer}>
        {questions.map((question, index) => (
          <ClickableAccordion key={index} text={question.title}>
            <p>{question.content}</p>
          </ClickableAccordion>
        ))}
      </div>
    </div>
  );
};
