import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.buttonPrimary,
    textAlign: "center",
    padding: "5rem 0",
  },
  headerBox: {
    padding: "0 10vmin",
    "& h1": {
      fontSize: "2.5rem",
      fontWeight: "bold",
      color: theme.colors.textPrimary,
      marginBlock: "2rem",
      "@media (max-width: 1000px)": {
        fontSize: "2rem",
      },
      "@media (max-width: 500px)": {
        fontSize: "1.5rem",
      },
    },
    "@media (max-width: 1000px)": {
      padding: "0 5vmin",
    },
    "@media (max-width: 500px)": {
      fontSize: "0.8rem",
    },
  },
  subHeaderBox: {
    padding: "2rem",
    borderRadius: "20px",
    backgroundColor: theme.colors.backgroundPrimary,
    filter: "drop-shadow(0 5px 3px #0003)",
    marginBlock: "3rem",
    maxWidth: "60%",
    margin: "0 auto",
    "@media (max-width: 1000px)": {
      padding: "1rem",
      maxWidth: "80%",
    },
    "@media (max-width: 500px)": {
      padding: "1rem",
      marginBlock: "1rem",
    },
  },
}));

interface Props {
  header: string;
  subHeader: string;
  children?: React.ReactNode;
}

export const InformationBox: React.FC<Props> = ({ header, subHeader, children }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerBox}>
        <h1>{header}</h1>
      </div>
      <div className={classes.subHeaderBox}>
        <p>{subHeader}</p>
        {children}
      </div>
    </div>
  );
};