import React from "react";
import { createUseStyles, Theme } from "react-jss";


const useStyles = createUseStyles((_theme: Theme) => ({
  '@keyframes rotate4': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes dash4': {
    '0%': {
      strokeDasharray: '1, 200',
      strokeDashoffset: 0,
    },
    '50%': {
      strokeDasharray: '90, 200',
      strokeDashoffset: '-35px',
    },
    '100%': {
      strokeDashoffset: '-125px',
    },
  },
  svg: {
    width: '2.25em',
    transformOrigin: 'center',
    animation: '$rotate4 2s linear infinite',
  },
  circle: {
    fill: 'none',
    stroke: 'hsl(214, 97%, 59%)',
    strokeWidth: 2,
    strokeDasharray: '1, 200',
    strokeDashoffset: 0,
    strokeLinecap: 'round',
    animation: '$dash4 1.5s ease-in-out infinite',
  },
  wrapper: {
    marginLeft: "-10px",
  }
}));


export const LoadingIcon: React.FC<{ isLoading: boolean, className?: string }> = ({ isLoading, className }) => {
  const classes = useStyles();

  if (!isLoading) {
    return null;
  }

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <svg className={classes.svg} viewBox="0 0 50 50">
        <circle className={classes.circle} cx="25" cy="25" r="20" />
      </svg>
    </div>
  );
};
