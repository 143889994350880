import { UserReview } from "Components/Common/UserReview";
import { createUseStyles, Theme } from "react-jss";
import UserImage from "assets/images/reviews/user-1.jpeg";

const useStyles = createUseStyles((theme: Theme) => ({
  reviewSectionContainer: {
    padding: "5rem 20vmin",
    backgroundColor: theme.colors.buttonPrimary,
    "& h1": {
      fontSize: "2rem",
      fontWeight: "bold",
      color: theme.colors.textPrimary,
      marginBlock: "2rem",
      "@media (max-width: 1000px)": {
        fontSize: "1.8rem",
      },
      "@media (max-width: 500px)": {
        fontSize: "1.5rem",
      },
    },
    "& h2": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.colors.textPrimary,
      "@media (max-width: 1000px)": {
        fontSize: "1.3rem",
      },
      "@media (max-width: 500px)": {
        fontSize: "1.1rem",
      },
    },
    "& p": {
      fontSize: "1.2rem",
      color: theme.colors.textPrimary,
      "@media (max-width: 1000px)": {
        fontSize: "1rem",
      },
      "@media (max-width: 500px)": {
        fontSize: "0.9rem",
      },
    },
    // less than 1000px
    "@media (max-width: 1000px)": {
      padding: "5rem 10vmin",
    },
    "@media (max-width: 800px)": {
      padding: "5rem 2vmin",
    },
  },
  reviewWrapper: {
    padding: "2rem 5rem",
    borderRadius: "15px",
    backgroundColor: theme.colors.backgroundPrimary,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 800px)": {
      padding: "2rem 3rem",
    },
    "@media (max-width: 600px)": {
      padding: "2rem 1.5rem",
    },
  },
  reviewContainer: {
    marginBlock: "2rem",
    width: "80%",
    maxWidth: "600px",
    marginInline: "auto",
    "@media (max-width: 1000px)": {
      width: "100%",
    }
  },
}));

interface Props {}

export const FaqReviewSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.reviewSectionContainer}>
      <div className={classes.reviewWrapper}>
        <h2>Våra användares upplevelser</h2>
        <h1>
          Helena fick en ersättning som hon inte ens visste att hon hade rätt
          till.
        </h1>
        <p>
          Många resenärer vet inte att de har rätt till ersättning för försenade
          tågresor. Klimra ser till att du får det du har rätt till, utan
          krångel
        </p>
        <div className={classes.reviewContainer}>
          <UserReview
            review={{
              score: 5,
              text: "Klimra tog hand om allt. Fick tillbaka pengar jag inte trodde jag skulle få.",
              name: "Helena Thybell",
              imageSrc: UserImage,
            }}
          />
        </div>
      </div>
    </div>
  );
};
