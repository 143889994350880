import { createUseStyles, Theme } from 'react-jss';

const useStyles = createUseStyles((theme: Theme) => ({
  h1: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '4.5rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '3rem',
    },
  },
  h2: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '3rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '2.5rem',
    },
  },
  h3: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '2.5rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '2.25rem',
    },
  },
  pageHeader: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '2.25rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '2rem',
    },
  },
  h4: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '1.2rem',
    },
  },
  h5: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '1rem',
    },
  },
  h6: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '0.9rem',
    },
  },
  h7: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '0.8rem',
    },
  },
  h8: {
    fontFamily: theme.typography.fontFamily,
    color: theme.colors.textPrimary,
    fontSize: '0.9rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    '@media screen and (max-width: 768px)': {
      fontSize: '0.65rem',
    },
  },
}));

interface Props {
  text: string;
  type: string;
  suffix?: any;
  sx?: any;
  onClick?: () => void;
}

const Text = (props: Props) => {
  const { text, sx, type, onClick, suffix } = props;
  const classes = useStyles();
  const [className, fontWeight] = type.split('.');

  return (
    <h6 className={classes[className as keyof typeof classes]} style={{ margin: '0px', ...sx, fontWeight }} onClick={onClick}>
      {text}
      <span>{suffix}</span>
    </h6>
  );
};

export default Text;
