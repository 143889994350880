import { BannerFooter, PropsBannerFooter } from "Components/Common/BannerFooter";
import React from "react";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  bannerContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.textSecondary,
    textAlign: "center",
    padding: "20px",
    boxSizing: "border-box",
    marginBottom: (props: any) => (props.spacing ? "2rem" : "0"),
    overflow: "hidden",
    "@media (max-width: 670px)": {
      padding: "2px",
    },
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -2,
  },
  imageTint: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: (props: any) =>  `rgba(0, 0, 0, ${props.tint})`,
    zIndex: -1, 
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "4rem",
    zIndex: 1,
    "@media (max-width: 670px)": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  contentNoChildren: {
    marginBottom: (props: any) => (props.children ? "4rem" : "10rem"),
    marginTop: "6rem",
  },
  bannerText: {
    fontSize: "3.75rem",
    fontWeight: "bold",
    marginTop: "75px",
    marginBottom: "0.5rem",
    width: "100%",
    "@media (max-width: 670px)": {
      fontSize: "8vw",
    },
  },
  bannerSubText: {
    fontSize: "1.5rem",
    marginBottom: "1rem",
    padding: "0 20px",
    maxWidth: "1400px",
    "@media (max-width: 670px)": {
      fontSize: "4vw",
    },
  },
}));

interface Props {
  title: string;
  subTitle: string;
  children?: React.ReactNode; 
  bannerFooter?: PropsBannerFooter;
  image?: string;
  spacing?: boolean;
  tint?: string;
}

export const Banner: React.FC<Props> = ({ title, subTitle, children, bannerFooter, image, spacing = true, tint = "0.2" }): JSX.Element => {
  const classes = useStyles({ children, image, spacing, tint });

  return (
    <>
      <div className={classes.bannerContainer}>
        <img src={image || ""} alt="Banner Background" className={classes.backgroundImage} />
        <div className={classes.imageTint} />
        <div className={classes.contentNoChildren}>
          <h1 className={classes.bannerText}>{title}</h1>
          <p className={classes.bannerSubText}>{subTitle}</p>
        </div>
        {children}
        {bannerFooter && <BannerFooter icon={bannerFooter.icon} text={bannerFooter.text} />}
      </div>
    </>
  );
};
