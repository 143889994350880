import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Box,
} from '@mui/material';
import Space from "./Space";
import ButtonLoadAndConfirm from "./Buttons/ButtonLoadAndConfirm";

const useStyles = createUseStyles({
    formContainer: (border: boolean) => ({
        display: 'flex',
        backgroundColor: 'white',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px',
        border: border ? '1px solid #ccc' : 'none',
        borderRadius: '8px',
    }),
    textField: {
        width: '100%',
        marginBottom: '10px',
    },
    checkbox: {
        textAlign: 'left',
        fontWeight: "200"
    },
    button: {
        marginTop: '20px',
    },
    formTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: 'black',
    },
    formSubTitle: {
        fontSize: '1rem',
        color: 'black',
    },
});

interface ReusableFormProps {
    title: string;
    subtitle?: string;
    textFields: { label: string; value?: string; mandatory?: boolean }[];
    checkboxes: { label: string; checked?: boolean; mandatory?: boolean }[];
    submitButtonText: string;
    onSubmit: (formData: { [key: string]: any }) => void;
    border: boolean;
}

const ReusableForm: React.FC<ReusableFormProps> = ({
                                                       title,
                                                       subtitle,
                                                       textFields,
                                                       checkboxes,
                                                       submitButtonText,
                                                       onSubmit,
                                                        border,
                                                   }) => {
    const classes = useStyles(border);

    const [formData, setFormData] = useState<{ [key: string]: any }>({
        ...textFields.reduce((acc, field) => ({ ...acc, [field.label]: field.value || '' }), {}),
        ...checkboxes.reduce((acc, box) => ({ ...acc, [box.label]: box.checked || false }), {}),
    });

    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        // Validate form whenever formData changes
        const newErrors: { [key: string]: boolean } = {};

        textFields.forEach((field) => {
            if (field.mandatory && !formData[field.label].trim()) {
                newErrors[field.label] = true;
            }
        });

        checkboxes.forEach((checkbox) => {
            if (checkbox.mandatory && !formData[checkbox.label]) {
                newErrors[checkbox.label] = true;
            }
        });

        setErrors(newErrors);
        setIsFormValid(Object.keys(newErrors).length === 0);
    }, [formData, textFields, checkboxes]);

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name: string, value: unknown }>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (isFormValid) {
            onSubmit(formData);
        }
        else {
            setShowErrors(true);
        }
    };

    return (
        <Box component="form" className={classes.formContainer} onSubmit={handleSubmit}>
            <h4 className={classes.formTitle}>{title}</h4>
            {subtitle && <p className={classes.formSubTitle}>{subtitle}</p>}

            {textFields.map((field) => (
                <>
                    <TextField
                        key={field.label}
                        className={classes.textField}
                        label={field.label}
                        name={field.label}
                        value={formData[field.label]}
                        onChange={handleTextChange}
                        variant="outlined"
                        fullWidth
                        error={(errors[field.label] || false) && showErrors}  // Show error if field is mandatory and not filled
                        helperText={errors[field.label] ? 'This field is required' : ''}
                    />
                    <Space type="small" />
                </>
            ))}
            <Space type="medium" />
            {checkboxes.map((checkbox) => (
                <FormControlLabel
                    key={checkbox.label}
                    control={
                        <Checkbox
                            className={classes.checkbox}
                            name={checkbox.label}
                            checked={formData[checkbox.label]}
                            onChange={handleCheckBoxChange}
                            color="primary"
                        />
                    }
                    label={checkbox.label}
                    className={`${classes.formSubTitle} ${classes.checkbox}`}
                />
            ))}
            <ButtonLoadAndConfirm
                text={submitButtonText}
                loading={false}
                style={{ padding: '1rem 2.5rem' }}
                onClick={(event: React.FormEvent) => handleSubmit(event)}
            />
        </Box>
    );
};

export default ReusableForm;
