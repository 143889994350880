import { ClickableAccordion } from "Components/Common/ClickableAccordion";
import { createUseStyles, Theme } from "react-jss";
import { faUserCircle, faSignOutAlt, faTrashAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const useStyles = createUseStyles((_theme: Theme) => ({
  faqContainer: {
    "& h1 ": {
      margin: "0",
    },
    padding: "5rem 30vmin",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    "@media (max-width: 1200px)": {
      padding: "2rem 20vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 850px)": {
      padding: "2rem 3vmin",
    },
  },
  headerSubtitle: {
    fontSize: "1rem",
    marginTop: "0.5rem",  
    color: "red"
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

interface Props {}

export const AccountSettings: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  const questions = [
    {
      title: "Mejl",
      content: "I förskott, ingenting. Klimra arbetar på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt krav inte lyckas. Om vi framgångsrikt driver din kompensation är vårt standardförfarande att tågbolaget överför pengarna direkt till dig, och därefter debiterar vi vår framgångsavgift på 20% på ditt betalkort.",
      subtitle: "Lägg till eller byt mejladress",
      icon: faEnvelope,
    },
    {
      title: "Konto",
      content: "Att välja Klimra innebär att du slipper krångliga processer och stress. Vi gör kompensationen för tågförseningar automatisk, enkel och rättvis. Genom att hantera allt åt dig ser vi till att du får den ersättning du har rätt till, utan att du behöver lyfta ett finger. Vårt mål är att göra din resa så smidig som möjligt. Dessutom arbetar vi på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt krav inte lyckas.",
      subtitle: "Ändra ditt konto",
      icon: faUserCircle,
    },
    {
      title: "Logga ut",
      content: "Vi täcker alla långdistanssträckor över 150 km där du reser med SJ tåg. Oavsett vilken destination du har inom detta intervall, kan du lita på att vi hanterar din kompensation om ditt tåg skulle bli försenat.",
      subtitle: "Logga ut från ditt konto",
      icon: faSignOutAlt,
    },
    {
      title: "Radera konto",
      content: "För resor med tåg som går en längre sträcka (150 km eller längre) gäller Tågpassagerarförordningen. Vid 60-119 minuters försening har du rätt till 25% av biljettpriset. Vid 120 minuters försening har du rätt till 50% av biljettpriset.",
      subtitle: "Radera ditt Klimra konto",
      icon: faTrashAlt,
    }
  ];

  return (
    <div className={classes.faqContainer}>
      <h1>Kontoinställningar</h1>
      <div className={classes.questionsContainer}>
        {questions.map((question, index) => (
          <ClickableAccordion
            key={index}
            text={question.title}
            icon={question.icon}  
            subText={question.subtitle}
          >
            <p>{question.content}</p>
          </ClickableAccordion>
        ))}
      </div>
    </div>
  );
};
