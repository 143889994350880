import { QuestionsAndAnswersSection } from "./childComponents/QuestionsAndAnswersSection";
import { FaqIntroductionSection } from "./childComponents/FaqIntroductionSection";
import { FaqBannerSection } from "./childComponents/FaqBannerSection";
import { FaqReviewSection } from "./childComponents/FaqReviewSection";
import { FaqBottomSection } from "./childComponents/FaqBottomSection";

export const Faq: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div>
      <FaqBannerSection />
      <FaqIntroductionSection />
      <QuestionsAndAnswersSection />
      <FaqReviewSection />
      <FaqBottomSection />
    </div>
  );
};
