import React from "react";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

interface FormQuestionProps {
	label: string;
	onChange: (value: string) => void;
}

const FormQuestion: React.FC<FormQuestionProps> = ({ label, onChange }) => (
	<FormControl style={{ alignItems: 'center', marginTop: "30px" }}>
		<FormLabel>{label}</FormLabel>
		<RadioGroup row onChange={(e) => onChange((e.target as HTMLInputElement).value)}>
			<FormControlLabel value="Ja" control={<Radio />} label="Ja" />
			<FormControlLabel value="Nej" control={<Radio />} label="Nej" />
			<FormControlLabel value="Vet ej" control={<Radio />} label="Vet ej" />
		</RadioGroup>
	</FormControl>
);

export default FormQuestion;
