import Text from "Components/Common/Text";
import Space from "Components/Common/Space";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { createUseStyles } from "react-jss";
import person0 from 'assets/images/reviews/person0.png';
import person1 from 'assets/images/reviews/person1.png';
import person2 from 'assets/images/reviews/person2.png';
import person3 from 'assets/images/reviews/person3.png';
import person4 from 'assets/images/reviews/person4.png';
import person5 from 'assets/images/reviews/person5.png';


const useStyles = createUseStyles({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  reviewCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  image: {
    borderRadius: '50%',
		width: '50%',
  },
  textContainer: {
    padding: '0.5rem',
    marginTop: '2rem',
		marginRight: "1rem",
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
});


interface ArrowProps {
	onClick?: () => void;
}

const NextSlider: React.FC<ArrowProps> = ({ onClick }): JSX.Element => {
	return (
		<button
			onClick={onClick}
			style={{
				cursor: 'pointer',
				position: 'absolute',
				right: '1%',
				top: '40%',
				zIndex: 1,
				background: 'none',
				border: 'none',
			}}
		>
			<FontAwesomeIcon icon={faChevronRight} size="3x" />
		</button>
  );
};


const PrevSlider: React.FC<ArrowProps> = ({ onClick }): JSX.Element => {
  return (
		<button
			onClick={onClick}
			style={{
				cursor: 'pointer',
				position: 'absolute',
				left: '1%',
				top: '40%',
				zIndex: 1,
				background: 'none',
				border: 'none',
			}}
		>
			<FontAwesomeIcon icon={faChevronLeft} size="3x" />
		</button>
  );
}


export const NewReviewSlideshow: React.FC<{}> = (): JSX.Element => {
	const classes = useStyles();

	const reviews = [
		{
			image: person0,
			text: 'Jag är så imponerad av Klimra. Fick ersättning helt automatiskt',
			name: 'Michael Malmgren'
		},
		{
			image: person1,
			text: 'Klimra tog hand om allt. Fick tillbaka pengar jag inte trodde jag skulle få',
			name: 'Helena Thybell'
		},
		{
			image: person2,
			text: 'Bra kundservice. Ringde dem på en lördag och fick svar direkt',
			name: 'Catharina Erlandsson'
		},
		{
			image: person3,
			text: 'På tiden att en sådan här tjänst skulle komma. Sjukt smidigt',
			name: 'Jacob Matz'
		},
		{
			image: person4,
			text: 'Hela min umgängeskrets använder er. Fem av fem i betyg',
			name: 'Olle Sjögren'
		},
		{
			image: person5,
			text: 'Grymt! Kunsumeternas rättigheter ska alltid stå först, inte tågbolagens',
			name: 'Johan Henriksson'
		}
	];
	var settings = {
		className: "center",
		arrows: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 1,
		nextArrow: <NextSlider />,
    prevArrow: <PrevSlider />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
    <div className={classes.container}>
      <Slider {...settings}>
        {reviews.map((element, index) => (
          <div key={index}>
            <div className={classes.reviewCard}>
              <img
                src={element.image}
                alt={element.name}
                className={classes.image}
              />
              <div className={classes.textContainer}>
                <div className={classes.textWrapper}>
                  <Text text={`"${element.text}".`} type="h6.200" />
                </div>
                <Space type="large" />
                <div className={classes.textWrapper}>
                  <Text text={element.name} type="h8.300" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
