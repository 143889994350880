import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { Banner } from "Components/Common/Banner";
import PersonOnTrain from "assets/images/faq/faq-banner.jpg";

interface Props {}

export const MyTripsBannerSection: React.FC<Props> = ({}): JSX.Element => {
  return (
    <Banner
      title="Har du rest med tåg nyligen?"
      subTitle="Vi hjälper dig att enkelt hålla koll på dina tågresor och förenklar processen för att ansöka om ersättning för försenade tåg."
      image={PersonOnTrain}
      bannerFooter={{
        icon: faHandHoldingDollar,
        text: "Fullt automatiserad process för att ansöka om ersättning",
      }}
      spacing={false}
    />
  );
};
