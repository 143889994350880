import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Banner } from "Components/Common/Banner";
import banner from 'assets/images/faq/faq-banner.jpg';


interface Props {}

export const FaqBannerSection: React.FC<Props> = ({}): JSX.Element => {
  return (
    <Banner
      title="Vanliga frågor"
      subTitle="Här kan du hitta svar till de vanligaste frågorna vi får om Klimra."
      bannerFooter={{
        icon: faPhone,
        text: "Ytterligare frågor? Ring oss på +46 761993236",
      }}
      spacing={false}
      image={banner}
      tint={"0.4"}
    />
  );
};
