import { createUseStyles, Theme } from "react-jss";
import { faStar, faStarHalfAlt, faStar as faStarEmpty } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = createUseStyles((theme: Theme) => ({
  reviewContainer: {
    backgroundColor: theme.colors.backgroundLightGrey,
    display: "grid",
    padding: "2rem 3rem 2rem 4rem",
    borderRadius: "15px",
    "& svg": {
      fill: "#FF9A62",
    },
    "@media (max-width: 600px)": {
      padding: "2rem 1.5rem",
    },
  },
  reviewProfile: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    marginTop: "1rem",
    "& img": {
      filter: "drop-shadow(0 5px 5px #0006)",
      borderRadius: "9999px",
      height: "3rem",
      width: "3rem",
      objectFit: "cover",
    },
  },
  reviewStars: {
    display: "flex",
    gap: "0.4rem",
    marginBottom: "0.5rem",
  },
}));

export interface UserReviewType {
  score: number;
  text: string;
  imageSrc: string;
  imageAlt?: string;
  name: string;
}

interface Props {
  review: UserReviewType;
}

export const UserReview: React.FC<Props> = ({ review }): JSX.Element => {
  const classes = useStyles();
  const cappedScore = Math.min(5, review.score);
  const fullStars = Math.floor(cappedScore);
  const isDecimalScore = cappedScore % 1 != 0;

  return (
    <div className={classes.reviewContainer}>
      <div className={classes.reviewStars}>
        {Array.from({ length: fullStars }).map((_, index) => (
          <FontAwesomeIcon icon={faStar} key={index} />
        ))}
        {isDecimalScore && <FontAwesomeIcon icon={faStarHalfAlt} />}
        {Array.from({ length: 5 - Math.ceil(cappedScore) }).map((_, index) => (
          <FontAwesomeIcon icon={faStarEmpty} key={index} />
        ))}
      </div>
      <p>{review.text}</p>
      <div className={classes.reviewProfile}>
        <img src={review.imageSrc} alt={review.imageAlt} />
        <h2>{review.name}</h2>
      </div>
    </div>
  );
};
