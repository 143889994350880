import { createUseStyles, Theme } from "react-jss";
import UserOne from "assets/images/reviews/user-1.jpeg";
import UserTwo from "assets/images/reviews/user-2.jpg";
import { UserReview, UserReviewType } from "Components/Common/UserReview";

const useStyles = createUseStyles((_theme: Theme) => ({
  testimonialsContainer: {
    margin: "4rem 0",
    padding: "2rem 20vmin",
    textAlign: "center",
    "@media (max-width: 1400px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "2rem 5vmin",
    },
  },
  reviewsContainer: {
    textAlign: "left",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridAutoRows: "1fr",
    alignItems: "center",
    gap: "3rem",
    marginTop: "4rem",
    "@media (max-width: 900px)": {
      gridTemplateColumns: "1fr",
    },
  },
}));

interface Props {}

export const TestimonialsSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  const reviews: UserReviewType[] = [
    {
      score: 5,
      text: "Klimra tog hand om allt. Fick tillbaka pengar jag inte trodde jag skulle få.",
      name: "Helena Thybell",
      imageSrc: UserOne,
      imageAlt: "User One",
    },
    {
      score: 5,
      text: "Jag är så imponerad av Klimra. Fick ersättning helt automatiskt.",
      name: "Michael Malmgren",
      imageSrc: UserTwo,
      imageAlt: "User Two",
    },
  ];

  return (
    <div className={classes.testimonialsContainer}>
      <h1>Vi har många nöjda användare</h1>
      <div className={classes.reviewsContainer}>
        {reviews.map((review, index) => (
          <UserReview review={review} key={index} />
        ))}
      </div>
    </div>
  );
};
