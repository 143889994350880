import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Banner } from "Components/Common/Banner";
import { ROUTES } from "constants/routes";
import { useStylesLogin } from "pages/login/Login";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { KlimraTheme } from "styles/theme";
import banner from "assets/images/login/register-login-banner.jpg";
import googleIcon from "assets/images/icons/googleIcon.png";
import { useState } from "react";
import KlimraForm from "Components/Common/KlimraForm";
interface CreateAccountFieldProps {
    loginClasses: any;
    navigate: any;
    setShowForm: any;
    notify: any;
}
const CreateAccountField: React.FC<CreateAccountFieldProps> = ({loginClasses, navigate, setShowForm, notify }): JSX.Element => {
    return (
        <div>
    <h1 className={loginClasses.title}>Skapa ett konto</h1>
        <p className={loginClasses.description}>Säg upp kontot när du vill</p>
        <button className={loginClasses.googleButton} onClick={() => {notify(); setShowForm(true)}} >
            <img src={googleIcon} alt="Google Logo" />
            Skappa ett konto via Google
        </button>
            <p className={loginClasses.createAccountText}>
                Har du redan ett konto?
                <a onClick={() => navigate(ROUTES.LOGIN)}> Logga in</a>
            </p>
        </div>
    )
}

export const Register: React.FC<{ }> = ({  }): JSX.Element => {
  const loginClasses = useStylesLogin();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false); //use this to show the sign up form
  const notify = () => toast.error(
    "Det finns för närvarande en väntelista. Registreringen av ett konto kommer att öppnas för allmänheten den 30 september 2024",
    {
      style: {
        borderRadius: "10px",
        background: KlimraTheme.colors.primary,
        color: KlimraTheme.colors.textPrimary,
      },
      duration: 10000,
    }
  )

  return (
    <div>
      <Banner
        title="Få full kontroll över dina tågresor"
        subTitle="Skappa ett konto för enkelt hantera alla dina tågresor. Följ
        dina resor smidigt och ansök snabbt om att få ersättning vid förseningar.
        Vi finns här för att göra din reseupplevelse enklare och stressfri.
        "
        bannerFooter={
          {
            icon: faLightbulb,
            text: "Din resa, vår prioritet",
          }
        }
        image={banner}
      >
        <div className={loginClasses.container}>
            {!showForm ? (<CreateAccountField loginClasses={loginClasses} navigate={navigate} setShowForm={setShowForm} notify={notify}/>) : 
                (
                <KlimraForm title={"Snart klar"} subtitle={"Helt gratis. Säg upp när du vill"}
                            textFields={[{label: "Förnamn", mandatory: true}, {label: "Efternamn", mandatory: true}, {label: "Personnummer", mandatory: true}, {label: "Telefonnummer", mandatory: true},  ]} checkboxes={[{label: "Jag har läst och godkänt användarvillkoren.", checked: false, mandatory: true}, {label: "Jag ger Klimra fullmakt att driva mina kompensationsärenden", checked: false, mandatory: true}]}
                            submitButtonText={"Skapa konto"}
                            onSubmit={() => navigate(ROUTES.LOGIN)}
                border={true}/>
            )}
        </div>
      </Banner>
    </div>
  );
}
