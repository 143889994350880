import { createUseStyles, Theme } from "react-jss";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { ROUTES } from "constants/routes";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = createUseStyles((_theme: Theme) => ({
  centeredColumnFlexGapContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5rem",
    margin: "150px 0 100px 0",
    paddingInline: "15vmin",
    textAlign: "center",
    "& > *": {
      marginBlock: "0",
    },
    "& p": {
      fontSize: "1.2rem",
      marginBlock: "0",
    },
    "@media (max-width: 1050px)": {
      paddingInline: "5vmin",
      gap: "3rem",
      "& p": {
        fontSize: "1rem",
        marginBottom: "1rem",
      },
    },
    "@media (max-width: 900px)": {
      "& h1": {
        fontSize: "1.5rem",
      },
      " & p": {
        fontSize: "0.9rem",
      },
    },
  },
}));

interface Props {}

export const ReadMoreInfoSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.centeredColumnFlexGapContainer}>
      <h1>
        Var tredje konsument har otillräcklig kunskap om sina rättigheter vid
        trafikstörningar.
      </h1>
      <AnchorButton path={ROUTES.MY_RIGHTS}>
        Läs mer <FontAwesomeIcon icon={faArrowRight} />
      </AnchorButton>
    </div>
  );
};
