import { createUseStyles, Theme } from "react-jss";
import { ClickableAccordion } from "Components/Common/ClickableAccordion";
import { ROUTES } from "constants/routes";
import { DarkBackgroundCard } from "Components/Common/DarkBackgroundCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import Space from "Components/Common/Space";

const useStyles = createUseStyles((_theme: Theme) => ({
  faqContainer: {
    "& h1": {
      margin: "0",
    },
    padding: "2rem 30vmin",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1300px)": {
      padding: "2rem 20vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 850px)": {
      padding: "2rem 3vmin",
    },
  },
  questionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    marginTop: "2rem",
  },
  questionsButton: {
    margin: "3rem 0 5rem 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonClass: {
    fontSize: "1rem",
    width: "fit-content",
    whiteSpace: "nowrap",
    marginLeft: "auto",
    "@media (max-width: 768px)": {
      margin: "0 auto",
    },
  },
}));

interface Props {}

export const FaqSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  const questions: { title: string; content: string }[] = [
    {
      title: "Hur mycket kostar det?",
      content: "I förskott, ingenting. Klimra arbetar på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt ärende inte lyckas. Om vi framgångsrikt driver in din kompensation tar vi en 20% kommission av ersättning innan pengarna, via swish, överförs till ditt konto.",
    },
    {
      title: "Varför välja oss?",
      content: "Att välja Klimra innebär att du slipper krångliga processer och stress. Vi gör kompensationen för tågförseningar automatisk, enkel och rättvis. Genom att hantera allt åt dig ser vi till att du får den ersättning du har rätt till, utan att du behöver lyfta ett finger. Vårt mål är att göra din resa så smidig som möjligt. Dessutom arbetar vi på en no-win no-fee-basis och det finns därför inga kostnader för dig om ditt krav inte lyckas.",
    },
    {
      title: "Vilka tågbolag och sträckor täcker vi?",
      content: "Vi täcker alla långdistanssträckor över 150 km där du reser med SJ tåg. Oavsett vilken destination du har inom detta intervall, kan du lita på att vi hanterar din kompensation om ditt tåg skulle bli försenat.",
    },
    {
      title: "Hur mycket ersättning har jag rätt till?",
      content: "För resor med tåg som går en längre sträcka (150 km eller längre) gäller Tågpassagerarförordningen. Vid 60-119 minuters försening har du rätt till 25% av biljettpriset. Vid 120 minuters försening har du rätt till 50% av biljettpriset",
    },
    {
      title: "Hur hanterar ni mina personuppgifter?",
      content: "Din integritet är viktig för oss. Vi är därför angelägna om att de personuppgifter som samlas in om dig lagras och hanteras på ett tryggt och säkert sätt, och i enlighet med dataskyddslagstiftningen. Dina personuppgifter lagras bara så länge som det krävs för att uppfylla ändamålen med behandlingen, eller så länge som vi måste lagra dem enligt lag. Därefter raderas eller avidentifieras dina uppgifter på ett säkert sätt, så att de inte längre kan kopplas till dig.",
    },
  ];

  return (
    <div className={classes.faqContainer}>
      <h1>Vanliga frågor</h1>
      <div className={classes.questionsContainer}>
        {questions.map((question, index) => (
          <ClickableAccordion key={index} text={question.title}>
            <p>{question.content}</p>
          </ClickableAccordion>
        ))}
      </div>
      <div className={classes.questionsButton}>
        <AnchorButton path={ROUTES.FAQ}>
          Fler Frågor <FontAwesomeIcon icon={faArrowRight} />
        </AnchorButton>
      </div>
      <DarkBackgroundCard>
        <h1>Undrar du något mer?</h1>
        <AnchorButton path={ROUTES.ABOUT_US} addedClassNames={classes.buttonClass} centerAlign={false}>
          Kontakta oss <FontAwesomeIcon icon={faArrowRight} />
        </AnchorButton>
      </DarkBackgroundCard>
      <Space type="large" />
    </div>
  );
};
