import HowItWorks3 from "assets/images/home/how-it-works-1.jpg";
import HowItWorks2 from "assets/images/home/how-it-works-2.jpg";
import HowItWorks1 from "assets/images/home/how-it-works-3.jpg";
import { createUseStyles, Theme } from "react-jss";
import { ROUTES } from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";

const useStyles = createUseStyles((theme: Theme) => ({
  paddedMainContent: {
    padding: "5rem 20vmin 2rem 20vmin",
    display: "flex",
    flexDirection: "column",
    "& h2": {
      fontSize: "1rem",
      fontWeight: "500",
      margin: "0",
    },
    "& h3": {
      fontSize: "2rem",
      fontWeight: "bold",
      margin: "10px 0 30px 0",
      "@media (max-width: 400px)": {
        fontSize: "1.5rem",
      },
    },
    "& h1": {
      marginBlock: "10px",
      fontSize: "1rem",
    },
    "& p": {
      fontSize: "0.85rem",
      margin: "0",
      "@media (max-width: 400px)": {
        fontSize: "0.7rem",
      },
    },
    "@media (max-width: 1480px)": {
      "& h1": {
        fontSize: "0.8rem",
      },
    },
    "@media (max-width: 1330px)": {
      padding: "3rem 10vmin",
    },
    "@media (max-width: 1200px)": {
      "& h1": {
        fontSize: "1rem",
      },
    },
    "@media (max-width: 880px)": {
      padding: "3rem 5vmin",
      "& h1": {
        fontSize: "0.8rem",
      },
    },
    "@media (max-width: 700px)": {
      "& h1": {
        fontSize: "1rem",
      },
    },
    "@media (max-width: 370px)": {
      "& h1": {
        fontSize: "0.7rem",
      },
    },
  },
  inlineCardContainer: {
    display: "grid",
    gap: "3rem",
    marginTop: "75px",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(3, 1fr)",
    "@media (min-width: 700px) and (max-width: 1200px)": {
      gridTemplateColumns: "repeat(2, 1fr)",
      "& > :nth-child(odd):last-child": {
        gridColumn: "span 2",
        justifySelf: "center",
        width: "50%",
      },
    },
    "@media (max-width: 700px)": {
      gridTemplateColumns: "1fr",
    },
  },
  stepCardImage: {
    aspectRatio: "3/2",
    width: "100%",
    objectFit: "cover",
    borderRadius: "20px",
  },
  stepCardNumber: {
    marginRight: "0.6rem",
    marginBottom: "0.2rem",
    borderRadius: "99999px",
    aspectRatio: "1/1",
    display: "inline-flex",
    height: "2.5rem",
    width: "2.5rem",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.secondaryComplement,
    color: theme.colors.textSecondary,
  },
  navButton: {
    border: "none",
    borderRadius: "9999px",
    margin: "15vmin auto",
    textDecoration: "none",
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.buttonPrimary,
    fontWeight: "bolder",
    padding: "1rem 2.5rem",
    filter: "drop-shadow(0 5px 5px #0006)",
    cursor: "pointer",
    "@media (max-width: 400px)": {
      fontSize: "0.8rem",
    },
  },
  howItWorksPWrapper: {
    "& p": {
      marginBottom: "5px",
    },
  }
}));

type MultiStepCard = {
  image: string;
  imageAlt?: string;
  heading: string;
  text: string;
};

export default function HowItWorksSection() {
  const classes = useStyles();

  const cards: MultiStepCard[] = [
    {
      heading: "Köp dina tågbiljetter som vanligt",
      text: "Fortsätt köpa dina tågbiljetter som vanligt. Med ett konto hos Klimra kan du enkelt vidarebefordra dina tågbiljetter via mail, så att vi automatiskt kan spåra både tidigare och kommande resor.",
      image: HowItWorks1,
      imageAlt: "Person using website",
    },
    {
      heading: "Res och luta dig tillbaka",
      text: "Om vi upptäcker att något av dina tåg har varit försenat, tar vi direkt hand om ditt kompensations ärende utan att du behöver lyfta ett finger.",
      image: HowItWorks2,
      imageAlt: "Person using mobile app",
    },
    {
      heading: "Få pengar in på ditt konto",
      text: "När ärendet är klart får du pengarna insatta på ditt konto. Vanligtvis tar det cirka 1-2 veckor från att förseningen inträffat.",
      image: HowItWorks3,
      imageAlt: "Person using mobile app",
    },
  ];

  return (
    <div className={classes.paddedMainContent}>
      <h2>Steg för steg</h2>
      <h3>Så här funkar det</h3>
      <div className={classes.howItWorksPWrapper}>
        <p>
          Klimra erbjuder en snabb och enkel lösning för att säkerställa att du
          får den ersättning du har rätt till vid tågförseningar.
        </p>
        <p>
          Genom att överlåta rätten att driva ditt ärende till oss, ser vi till att du
          snabbt får tillbaka de pengarna du har rätt till.
        </p>
      </div>
      <MultiStepCardView cards={cards} />
      <AnchorButton path={ROUTES.REGISTER} addedClassNames={classes.navButton}>
        Kräv kompensation <FontAwesomeIcon icon={faArrowRight} />
      </AnchorButton>
    </div>
  );
}

type MultiStepCardViewProps = {
  cards: MultiStepCard[];
};
function MultiStepCardView({ cards }: MultiStepCardViewProps) {
  const classes = useStyles();

  return (
    <div className={classes.inlineCardContainer}>
      {cards.map((card, index) => (
        <div key={`multistep-card-view-card-${index}`}>
          <img
            src={card.image}
            alt={card.imageAlt}
            className={classes.stepCardImage}
          />
          <h1>
            <span className={classes.stepCardNumber}>{index + 1}</span>
            {card.heading}
          </h1>
          <p>{card.text}</p>
        </div>
      ))}
    </div>
  );
}
