import { PropsWithChildren, useState, useRef, useEffect } from "react";
import { createUseStyles, Theme } from "react-jss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles((theme: Theme) => ({
  accordion: {
    backgroundColor: theme.colors.backgroundLightGrey,
    position: "relative",
    zIndex: "2",
    borderRadius: "10px",
    overflow: "hidden",
  },
  accordionHeader: {
    padding: "2rem 1.5rem",
    cursor: "pointer",
    "& *": {
      margin: "0",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
  accordionButton: {
    border: "none",
    transition: "transform 300ms ease",
    color: theme.colors.textPrimary,
  },
  headerTextContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",  
  },
  headerIcon: {
    fontSize: "2rem",  
  },
  headerTitle: {
    fontSize: "1.2rem",
    color: theme.colors.textPrimary,
  },
  headerSubtitle: {
    fontSize: "1rem",
    color: theme.colors.textPrimary, 
    marginTop: "0.5rem",  
  },
  accordionContent: {
    backgroundColor: theme.colors.backgroundLightGrey,
    transition: "height 300ms ease",
    height: "0",
    paddingInline: "1.5rem",
    borderRadius: "inherit",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    zIndex: "1",
    overflow: "hidden",
    marginBottom: (props: { isExpanded: boolean }) => props.isExpanded ? "2rem" : "0",
  },
  expandedAccordionContent: {
    height: "auto",
    paddingBlock: "1.5rem",
  },
  collapsedAccordionContent: {
    height: "0",
    paddingBlock: "0",
  },
  expandedAccordionButton: {
    transform: "rotate(-180deg)",
  },
  collapsedAccordionButton: {
    transform: "rotate(0deg)",
  },
}));

interface Props {
  text: string;
  icon?: IconProp;
  subText?: string;
  children: React.ReactNode;
}

export const ClickableAccordion: React.FC<PropsWithChildren<Props>> = ({ text, icon, subText, children }): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number | string>("0px");
  const contentRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles({ isExpanded });

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [isExpanded]);

  const toggleAccordion = () => {
    setIsExpanded((prev) => !prev);
  };


  return (
    <div className={classes.accordion}>
      <div
        className={classes.accordionHeader}
        onClick={toggleAccordion}
        role="button"
        aria-expanded={isExpanded}
        aria-controls="accordion-content"
      >
        <div className={classes.headerContainer}>
          <div className={classes.headerTextContainer}>
            {icon && <FontAwesomeIcon icon={icon} className={classes.headerIcon} />}
            <div>
              <h1 className={classes.headerTitle}>{text}</h1>
              {subText && <p className={classes.headerSubtitle}>{subText}</p>}
            </div>
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`${classes.accordionButton} ${
              isExpanded
                ? classes.expandedAccordionButton
                : classes.collapsedAccordionButton
            }`}
          />
        </div>
      </div>
      <div
        id="accordion-content"
        ref={contentRef}
        className={classes.accordionContent}
        style={{ height: `${contentHeight}px` }}
        aria-hidden={!isExpanded}
      >
        {children}
      </div>
    </div>
  );
};
