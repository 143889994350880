import CountUp from "react-countup";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  mainContainer: {
    backgroundColor: theme.colors.primary,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    paddingBlock: "5rem",
    gap: "12rem",
    "& h1": {
      fontSize: "5rem",
      margin: "0",
      fontWeight: "bolder",
    },
    "& p": {
      marginBlock: "5px",
    },
    "@media (max-width: 700px)": {
      paddingBlock: "2rem",
      flexDirection: "column",
      gap: "0",
      "& div": {
        marginBlock: "1rem",
      },
    },
  },
}));

interface Props {}

export const StatisticsSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div>
        <h1>{<CountUp end={72} duration={2} enableScrollSpy scrollSpyOnce />}h</h1>
        <p>pengarna tillbaka inom 72 timmar</p>
      </div>
      <div>
        <h1><CountUp end={413} duration={2} enableScrollSpy scrollSpyOnce />:-</h1>
        <p>betalar vi ut i snitt per ärende</p>
      </div>
    </div>
  );
};
