import React, { useState, useEffect, ReactElement } from 'react';
import { createUseStyles, Theme } from 'react-jss';
import { Box, Button, Modal } from "@mui/material";
import { CookiesInformation } from "./childComponent/CookiesInformation";
import { useCookies } from "react-cookie";
import logo from "assets/images/logo/logo.png";

const modalStyle= {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80%',
  width: "80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  overflowY: 'scroll',
  '@media (max-width: 500px)': {
    height: '60%',
  }
}

const useStyles = createUseStyles((theme: Theme) => ({
  popupContainer: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '900px',
    backgroundColor: theme.colors.backgroundPrimary,
    color: theme.colors.textPrimary,
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    zIndex: 1000,
    marginBottom: '5px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      width: '85%',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: "10px"
    },
  },
  textContainer: {
    flex: 1,
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    alignItems: 'center',
    '@media (max-width: 700px)': {
      marginTop: '20px',
    },
  },
  acceptButton: {
    backgroundColor: theme.colors.buttonPrimary,
    border: 'none',
    padding: '12px 30px',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.colors.textPrimary,
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.colors.buttonHover,
    },
    marginBottom: '10px',
  },
  privacyLink: {
    color: theme.colors.textPrimary,
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'center',
    border: 'none',
    backgroundColor: 'transparent',
  },
  logo: {
    marginBottom: '10px',
    width: '200px',
  },
}));

interface Props {}

export const CookieConsentPopup: React.FC<Props> = (): ReactElement<any, any> | null => {
  const [cookies, setCookies] = useCookies(['cookiesAccepted']);
  const [open, setOpen] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false);

  const classes = useStyles();
  
  useEffect(() => {
    const accepted: boolean = cookies['cookiesAccepted'];
    setCookiesAccepted(accepted);
  }, [cookies]);

  const handleAcceptCookies = () => {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    setCookies('cookiesAccepted', "true", {
      path: '/',
      sameSite: 'strict',
      expires: expiryDate,
    });
    setCookiesAccepted(true);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  if (cookiesAccepted) {
    return null;
  }

  return (
    <div className={classes.popupContainer}>
      <div className={classes.textContainer}>
        <img src={logo} alt="Logo" className={classes.logo} />
        <span>
          Hej, vi och våra partners använder cookies för att analysera trafik och anpassa innehåll. Klicka för att ge ditt samtycke och spara dina inställningar (du kan alltid ändra dem senare).
        </span>
      </div>
      <div className={classes.buttonContainer}>
        <button className={classes.acceptButton} onClick={handleAcceptCookies}>
          Acceptera cookies
        </button>
        <button className={classes.privacyLink} onClick={handleOpen} aria-label={`cookies-concent`}>
          Läs mer om cookies
        </button>
      </div>
      <Modal open={open} onClose={handleClose} aria-labelledby="Modal for cookies" aria-describedby="Information for accepting Cookies at klimra">
        <Box sx={modalStyle}>
          <div style={{listStylePosition: 'inside', display: 'flex', justifyContent: 'center'}}>
          <CookiesInformation style={{position: 'absolute'}}/>
          </div>
          <Button onClick={handleClose} variant="contained">Stäng</Button>
        </Box>
      </Modal>
    </div>
  );
};
