import React from "react";
import { useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useButtonStyle } from "./buttonStyle";
import { LoadingIcon } from "Components/Misc/LoadingIcon";

interface ButtonLoadAndConfirmProps {
  buttonStyle?: string;
  iconStyle?: any;
  text: string;
  path?: string;
  loading: boolean;
  disabled?: boolean;
  onClick?: (() => void) | ((event: React.SyntheticEvent) => void);
  style?: React.CSSProperties;
}

const useStyles = createUseStyles({
  button: {
    width: "250px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    border: "none",
    cursor: "pointer",
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const ButtonLoadAndConfirm: React.FC<ButtonLoadAndConfirmProps> = (props) => {
  const { buttonStyle, iconStyle, style, text, path, loading, disabled = false, onClick } = props;
  const buttonClasses = useButtonStyle({ centerAlign: true });
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (event: React.SyntheticEvent) => {
    if (!disabled) {
      if (path) {
        navigate(path);
      }
      if (onClick) {
        if (onClick.length > 0) {
          (onClick as (event: React.SyntheticEvent) => void)(event);
        } else {
          (onClick as () => void)();
        }
      }
    }
  };

	console.log(loading)

  return (
    <button
      style={style}
      className={`${classes.button} ${buttonClasses.anchorButton} ${buttonClasses.anchorLoading} ${buttonStyle} ${disabled ? buttonClasses.disabled : ""}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoadingIcon isLoading={loading} className={`${buttonClasses.iconLoading} ${iconStyle}`} />
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default ButtonLoadAndConfirm;
