import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  exampleClass: {
    exampleColor: theme.colors.primary,
  },
}));

export const FirstTime: React.FC<{ }> = ({  }): JSX.Element => {
  // @ts-ignore
  const classes = useStyles();

  return (
    <div>
    </div>
  );
}