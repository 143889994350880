import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { Banner } from "Components/Common/Banner";
import { ROUTES } from "constants/routes";
import { createUseStyles, Theme } from "react-jss";
import { Terms } from "./childComponents/Terms";
import image from "assets/images/faq/faq-banner.jpg";

const useStyles = createUseStyles((_theme: Theme) => ({
  lineBreak: {
    marginBottom: "5rem",
  },
}));

export const UserTerms: React.FC<{ }> = ({  }): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Banner
      title="Användarvillkor"
      subTitle=""
      bannerFooter={{
        icon: faPhone,
        text: "Ytterligare frågor? Ring oss på +46 761993236",
      }}
      image={image}
      >
        <AnchorButton path={ROUTES.HOME}>Klicka här för att komma till startsidan</AnchorButton>
        <div className={classes.lineBreak} />
      </Banner>
      <Terms />
    </div>
  );
}