import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Banner} from "Components/Common/Banner";
import { ROUTES } from "constants/routes";
import { createUseStyles, Theme } from "react-jss";
import { useNavigate } from "react-router-dom";
import banner from "assets/images/login/register-login-banner.jpg";
import googleIcon from "assets/images/icons/googleIcon.png";

export const useStylesLogin = createUseStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '70px 100px',
    backgroundColor: theme.colors.backgroundPrimary,
    borderRadius: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    width: '60vw',
    maxWidth: '400px',
    margin: '30px 0 200px 0',
    "@media (max-width: 900px)": {
      width: '85%',
      padding: '40px 20px',
    },
    "@media (max-width: 550px)": {
      width: '90%',
      padding: '40px 20px',
    },
    "@media (max-width: 400px)": {
      padding: '20px 10px',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
    color: theme.colors.textPrimary,
  },
  description: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '30px',
    textAlign: 'center',
  },
  googleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    backgroundColor: '#e9f4ec',
    borderRadius: '10px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#000',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    textDecoration: 'none',
    '& img': {
      marginRight: '10px',
      width: '24px',
    },
  },
  createAccountText: {
    fontSize: '1rem',
    color: '#666',
    textAlign: 'center',
    cursor: 'pointer',
    '& a': {
      color: '#000',
      textDecoration: 'underline',
      fontWeight: 'bold',
      marginLeft: '5px',
    },
  },
}));

export const Login: React.FC<{ }> = ({  }): JSX.Element => {
  const classes = useStylesLogin();
  const navigate = useNavigate();

  return (
    <div>
      <Banner
        title="Få full kontroll över dina tågresor"
        subTitle="Logga in för enkelt hantera alla dina tågresor. Följ
        dina resor smidigt och ansök snabbt om att få ersättning vid förseningar.
        Vi finns här för att göra din reseupplevelse enklare och stressfri.
        "
        bannerFooter={
          {
            icon: faLightbulb,
            text: "Din resa, vår prioritet",
          }
        }
        image={banner}
      >
        <div className={classes.container}>
          <h1 className={classes.title}>Välkommen!</h1>
          <p className={classes.description}>Logga in på ditt konto för att se dina ärenden</p>
          <button className={classes.googleButton}>
            <img src={googleIcon} alt="Google Logo" />
            Logga in via Google
          </button>
          <p className={classes.createAccountText}>
            Har du inget konto?
            <a onClick={() => navigate(ROUTES.REGISTER)} aria-label={`register-account`}> Skapa ett konto.</a>
          </p>
        </div>
      </Banner>
    </div>
  );
}
