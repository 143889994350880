import React from "react";
import { createUseStyles, Theme } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

const useStyles = createUseStyles((theme: Theme) => ({
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '10px',
  },
  navLinks: {
    display: 'flex',
    gap: '20px',
    listStyle: 'none',
    marginRight: '25px',
    padding: 0,
  },
  navLinksLanguage: {
    display: 'flex',
    gap: '12px',
    listStyle: 'none',
    marginLeft: "50px",
    padding: 0,
  },
  navLink: {
    fontSize: '16px',
    color: theme.colors.textSecondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  contactButton: {
    backgroundColor: theme.colors.buttonPrimary,
    borderRadius: '20px',
    padding: '12px 20px',
    fontSize: '16px',
    color: '#000',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.buttonHover,
    },
  }
}));

export const DesktopNavigation: React.FC<{}> = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.topRow}>
        <ul className={classes.navLinks}>
          <li><Link to={ROUTES.LOGIN} className={classes.navLink}>Logga in</Link></li>
          <li><Link to={ROUTES.REGISTER} className={classes.navLink}>Skapa konto</Link></li>
          <li><Link to={ROUTES.MY_RIGHTS} className={classes.navLink}>Dina rättigheter</Link></li>
          <li><Link to={ROUTES.ABOUT_US} className={classes.navLink}>Om oss</Link></li>
          <li><Link to={ROUTES.FAQ} className={classes.navLink}>FAQ</Link></li>
        </ul>
      </div>
      <div className={classes.bottomRow}>
        <button className={classes.contactButton} onClick={() => navigate(ROUTES.ABOUT_US)}>Kontakta oss</button>
      </div>
    </>
  );
};
