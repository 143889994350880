import { InformationBox } from "Components/Common/InformationBox";

interface Props {}

export const FaqIntroductionSection: React.FC<Props> = ({}): JSX.Element => {
  return (
    <InformationBox
      header="Vanliga Frågor - Vi har Svaren!"
      subHeader="Vi vet att frågor om kompensation för tågforseningar och vår ansökningsprocess kan dyka upp. Ta en titt nedan för att hitta svaren på våra vanligaste frågor - vi har gjort det enkelt för dig!"
    />
  );
};
