import {Banner} from "Components/Common/Banner";
import {faHandHoldingHeart} from "@fortawesome/free-solid-svg-icons";
import { Compensation } from "./childComponents/Compensation";
import { AccountSettings } from "./childComponents/AccountSettings";
import { BenefitsSectionSettings } from "./childComponents/BenefitSectionSettings";
import { AdditionalQuizSection } from "./childComponents/AdditionalQuizSection";
import banner from 'assets/images/settings/settings-banner.jpg';


export const Settings: React.FC<{ }> = ({  }): JSX.Element => {
  return (
    <div>
        <Banner
          title="Inställningar"
          subTitle="Här kan du anpassa dina preferenser och hantera ditt Klimra-konto"
          bannerFooter={{
            icon: faHandHoldingHeart,
            text: "Vi är här för att hjälpa dig"
          }}
          image={banner}
          spacing={false}
        />
        <Compensation />
        <AccountSettings />
        <BenefitsSectionSettings />
        <AdditionalQuizSection />
    </div>
  );
}