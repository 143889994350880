import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { DarkBackgroundCard } from "Components/Common/DarkBackgroundCard";
import { ROUTES } from "constants/routes";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  questionsSection: {
    "& > *": {
      marginInline: "auto",
    },
  },
  quoteSection: {
    backgroundColor: theme.colors.buttonPrimary,
    fontSize: "1.3rem",
    padding: "3rem 30vmin",
    textAlign: "center",
    fontWeight: "bolder",
    "& h1": {
      margin: "0",
      color: theme.colors.textPrimary,
      fontSize: "2rem",
      "@media (max-width: 1300px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 550px)": {
        fontSize: "1.5rem",
      },
      "@media (max-width: 400px)": {
        fontSize: "1.1rem",
      },
    },
    "@media (max-width: 1300px)": {
      padding: "2rem 20vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "2rem 10vmin",
    },
    "@media (max-width: 850px)": {
      padding: "2rem 3vmin",
    },
  },
  questionCard: {
    padding: "5rem",
    paddingInline: "25vmin",
    "@media (max-width: 1300px)": {
      padding: "5rem 20vmin",
    },
    "@media (max-width: 1000px)": {
      padding: "5rem 10vmin",
    },
    "@media (max-width: 850px)": {
      padding: "5rem 3vmin",
    },
  },
  buttonClass: {
    fontSize: "1rem",
    width: "fit-content",
    whiteSpace: "nowrap",
    marginLeft: "auto",
    "@media (max-width: 768px)": {
      margin: "0 auto",
    },
  },
}));

interface Props {}

export const QuestionsSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.questionsSection}>
      <div className={classes.quoteSection}>
        <h1>
          “Med Klimra sparar du tid, undviker stress och ser till att du alltid
          får korrekt ersättning - så att du kan fokusera på att njuta av din
          resa.”
        </h1>
      </div>
      <div className={classes.questionCard}>
        <DarkBackgroundCard>
          <h1>Har du några frågor?</h1>
          <AnchorButton path={ROUTES.ABOUT_US} addedClassNames={classes.buttonClass} centerAlign={false}>
            Kontakta oss <FontAwesomeIcon icon={faArrowRight} />
          </AnchorButton>
        </DarkBackgroundCard>
      </div>
    </div>
  );
};
