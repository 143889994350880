import { faCircle, faCircleCheck, faGears, faMoneyBill, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUseStyles, Theme } from "react-jss";
import { Trip } from "types/Trip";

const useStyles = createUseStyles((theme: Theme) => ({
  tripContainer: {
    borderRadius: "20px",
    backgroundColor: theme.colors.backgroundPrimary,
    padding: "1rem 2rem",
    filter: "drop-shadow(0 5px 3px #0006)",
    display: "grid",
    gap: "1rem",
    alignItems: "center",
    gridAutoFlow: "column",
    gridTemplateColumns: "1fr auto 1fr",
    "@media (max-width: 1300px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  first: {
    "@media (max-width: 1300px)": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  tripView: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    marginInline: "auto",
    position: "relative",
    fontSize: "1.5rem",
  },
  tripStatusBar: {
    display: "flex",
    marginInline: "auto",
    alignItems: "center",
    width: "200px",
  },
  timeView: {
    width: "220px",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: "2rem",
    justifySelf: "center",
    alignSelf: "center",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
  },
  horizontalLine: {
    borderBottom: "1px solid black",
    width: "100%",
  },
  paymentStatus: {
    "& p": {
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    "& svg": {
      fontSize: "2rem",
    },
    justifySelf: "flex-end",
    fontSize: "1.5rem",
  },
  delayBox: {
    gridRow: "2",
    gridColumn: "1 / span all",
  },
  delayStatusInfo: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2rem",
    justifyContent: "space-around",
    "& > div": {
      textAlign: "center",
      "& svg": {
        fontSize: "4rem",
      },
    },
  },
}));

interface Props {
  trip: Trip;
}

export const TripCard: React.FC<Props> = ({ trip }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.tripContainer}>
      <div className={classes.first}>
        <h2>
          <strong>{trip.type == "ongoing" ? "Idag" : trip.date}</strong>
        </h2>
        <p>
          {trip.from} - {trip.to}
        </p>
        <p>
          {trip.trainCompany}, tåg {trip.trainNumber}
        </p>
        <p>
          <strong>{trip.delayMinutes} min </strong>försenad
        </p>
      </div>
      <div className={classes.tripView}>
        <p>
          <strong>{trip.from}</strong>
        </p>
        <div className={classes.tripStatusBar}>
          <div className={classes.timeView}>
            <p>{trip.departureTime}</p>
            <p>{trip.arrivalTime}</p>
          </div>
          <FontAwesomeIcon icon={faCircle} />
          <div className={classes.horizontalLine} />
          {trip.type == "upcoming" ? (
            <FontAwesomeIcon icon={faCircle} />
          ) : trip.type == "previous" ? (
            <FontAwesomeIcon icon={faCircleCheck} />
          ) : (
            <FontAwesomeIcon icon={faSpinner} />
          )}
        </div>
        <p>
          <strong>{trip.to}</strong>
        </p>
      </div>
      <div className={classes.paymentStatus}>
        {trip.status == "processing" && (
          <p>
            <FontAwesomeIcon icon={faGears} /> Pågående process
          </p>
        )}
        {trip.status == "ineligible" && (
          <p>
            <FontAwesomeIcon icon={faMoneyBill} /> Inget att begära
          </p>
        )}
        {trip.status == "paid" && (
          <p>
            <FontAwesomeIcon icon={faMoneyBill} /> Utbetald
          </p>
        )}
        {trip.status == "future" && (
          <p>
            <FontAwesomeIcon icon={faCircle} /> Tåget har ännu inte avgått
          </p>
        )}
      </div>
      {trip.type == "ongoing" && (
        <div className={classes.delayBox}>
          {trip.delay ? (
            <>
              <div className={classes.delayStatusInfo}>
                <div>
                  <FontAwesomeIcon icon={faClock} />
                  <h1>Förseningsorsak</h1>
                  <p>{trip.delay.reason}</p>
                </div>
                <div>
                  <FontAwesomeIcon icon={faClock} />
                  <h1>{trip.delayMinutes} minuters försening</h1>
                </div>
                <div>
                  <FontAwesomeIcon icon={faMoneyBill} />
                  <h1>Min kompensation</h1>
                  <p>
                    Du är för nuvarande berättigad till{" "}
                    {trip.delay.compensationPercentage}% i kompensation.
                  </p>
                </div>
              </div>
              {trip.delay.compensationStatus == "started" && (
                <p>* Klimra har redan påbörjat din process</p>
              )}
            </>
          ) : (
            <p>
              * Klimra övervakar noggrant din resa för att säkerställa en smidig
              stressfri upplevelse
            </p>
          )}
        </div>
      )}
    </div>
  );
};
