import { InformationBox } from "Components/Common/InformationBox";
import { InfoSlideWithImage } from "Components/Common/InfoSlideWithImage";
import { createUseStyles, Theme } from "react-jss";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { ROUTES } from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Trip } from "types/Trip";
import { TripCard } from "./TripCard";
import PersonOnTrain from "assets/images/faq/faq-banner.jpg";

const useStyles = createUseStyles((theme: Theme) => ({
  tripsContainer: {
    backgroundColor: theme.colors.buttonPrimary,
    padding: "2rem 20vmin",
    "@media (max-width: 1300px)": {
      padding: "2rem",
    },
    "& > div": {
      paddingBlock: "2rem",
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
  },
}));

interface Props {}

export const MyTripsSection: React.FC<Props> = ({}): JSX.Element => {
  // @ts-ignore
  const classes = useStyles();

  // TODO: Change this sample data to real data from API call
  const trips: Trip[] = [
    {
      from: "Stockholm C",
      to: "Göteborg C",
      trainCompany: "SJ",
      trainNumber: "167",
      ticketNumber: "1321312312",
      date: "2024/09/05",
      delayMinutes: 0,
      departureTime: "20:23",
      arrivalTime: "21:49",
      status: "paid",
      type: "previous",
    },
    {
      from: "Stockholm C",
      to: "Göteborg C",
      trainCompany: "SJ",
      trainNumber: "167",
      ticketNumber: "1321312312",
      date: "2024/09/05",
      delayMinutes: 0,
      departureTime: "20:23",
      arrivalTime: "21:49",
      status: "processing",
      type: "ongoing",
      delay: {
        reason:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam maxime quis ipsa fugit, nihil illo.",
        compensationPercentage: 20,
        compensationStatus: "started",
      },
    },
    {
      from: "Stockholm C",
      to: "Göteborg C",
      trainCompany: "SJ",
      trainNumber: "167",
      ticketNumber: "1321312312",
      date: "2024/09/05",
      delayMinutes: 0,
      departureTime: "20:23",
      arrivalTime: "21:49",
      status: "ineligible",
      type: "upcoming",
    },
  ];

  if (trips.length == 0) {
    return (
      <>
        <InformationBox
          header="Mina resor"
          subHeader="Här kommer dina tågresor att dyka upp. Vanligtvis tar det oss en timme att granska resorna för att avgöra om du är berättigad kompensation eller inte. Skulle din resa inte dyka upp kan du kontakta oss."
        />
        <InfoSlideWithImage
          title="Hur Klimra förbättrar din reseupplevelse:"
          imageSrc={PersonOnTrain}
          imageAlt="Person on train looking down at phone"
        >
          <ul>
            <li>Vi övervakar din resa, så du slipper.</li>
            <li>
              Vi ger dig realtidsuppdateringar för att hålla dig informerad.
            </li>
            <li>Vi hanterar alla ersättningskrav åt dig.</li>
            <li>
              Vi säkerställer en stressfri resa med smidig support i varje steg.
            </li>
          </ul>
          <AnchorButton path={ROUTES.REGISTER}>
            Kräv kompensation <FontAwesomeIcon icon={faArrowRight} />
          </AnchorButton>
        </InfoSlideWithImage>
      </>
    );
  }

  const ongoingTrip = trips.find((trip) => trip.type == "ongoing");
  const upcomingTrips = trips.filter((trip) => trip.type == "upcoming");
  const previousTrips = trips.filter((trip) => trip.type == "previous");

  return (
    <div className={classes.tripsContainer}>
      <center>
        <h1>Mina resor</h1>
      </center>
      {ongoingTrip && (
        <div>
          <h2>Pågående resa</h2>
          <TripCard trip={ongoingTrip} />
        </div>
      )}
      {upcomingTrips.length > 0 && (
        <div>
          <h2>Kommande resor</h2>
          {upcomingTrips.map((trip, index) => (
            <TripCard trip={trip} key={index} />
          ))}
        </div>
      )}
      {previousTrips.length > 0 && (
        <div>
          <h2>Tidigare resor</h2>
          {previousTrips.map((trip, index) => (
            <TripCard trip={trip} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};
