import { MainBannerSection } from "./childComponents/MainBannerSection";
import HowItWorksSection from "./childComponents/HowItWorksSection";
import { FaqSection } from "./childComponents/FAQSection";
import { StatisticsSection } from "Components/Common/StatisticsSection";
import { ReadMoreInfoSection } from "./childComponents/ReadMoreInfoSection";
import { TestimonialsSection } from "./childComponents/TestimonialsSection";
import { BenefitsSection } from "./childComponents/BenefitsSection";

export const Home: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div>
      <MainBannerSection />
      <HowItWorksSection />
      <StatisticsSection />
      <ReadMoreInfoSection />
      <BenefitsSection />
      <TestimonialsSection />
      <FaqSection />
    </div>
  );
};
