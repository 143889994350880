interface KlimraThemeStructure {
  colors: {
    primary: string;
    secondary: string;
    secondaryComplement: string;
    buttonPrimary: string;
    buttonHover: string;
    backgroundPrimary: string;
    backgroundLightGrey: string;
    textPrimary: string;
    textSecondary: string;
    textGray: string;
    danger: string;
    backgroundGray: string;
  };
  typography: {
    fontFamily: string;
  };
}

declare module 'react-jss' {
  interface Theme extends KlimraThemeStructure {}
}

export const KlimraTheme: KlimraThemeStructure = {
  colors: {
    primary: '#D6E1D4',
    secondary: '#719394',
    secondaryComplement: '#334647',
    buttonPrimary: '#E9F9E7',
    buttonHover: '#C8E6C9',
    backgroundPrimary: '#FFFFFF',
    backgroundLightGrey: '#ECF0F1',
    textPrimary: '#000000',
    textSecondary: '#FFFFFF',
    textGray: '#B0B0B0',
    danger: '#FF0000',
    backgroundGray: '#ECF0F1',
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
};
