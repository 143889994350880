import { createUseStyles, Theme } from "react-jss";
import { MyTripsBannerSection } from "./childComponents/MyTripsBannerSection";
import { MyTripsSection } from "./childComponents/MyTripsSection";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { ROUTES } from "constants/routes";
import { DarkBackgroundCard } from "Components/Common/DarkBackgroundCard";
import { CenteredContentBox } from "Components/Common/CenteredContentBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles((theme: Theme) => ({
  paddedContainer: {
    backgroundColor: theme.colors.backgroundLightGrey,
    padding: "5rem 30vmin",
    "@media (max-width: 1300px)": {
      padding: "5rem 2rem",
    },
  },
}));

interface Props {}

export const MyTrips: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <MyTripsBannerSection />
      <MyTripsSection />
      <CenteredContentBox>
        <h1>Enkel resa med Klimra: Din Resa, Vår Prioritet</h1>
        <p>
          Klimra kommer att ta hand om alla dina tågrelaterade problem och se
          till att din resa blir både smidig och bekymmerfri. Vårt dedikerade
          team arbetar för att hantera alla detaljer och lösa eventuella problem
          så snabbt och effektivt som möjligt. Vi är här för att göra din resa
          så enkel och bekväm som möjligt, så att du kan fokusera på att njuta
          av din tid utan att behöva oroa dig för detaljerna.
        </p>
        <AnchorButton path={ROUTES.ABOUT_US}>
          Läs mer <FontAwesomeIcon icon={faArrowRight} />
        </AnchorButton>
      </CenteredContentBox>
      <div className={classes.paddedContainer}>
        <DarkBackgroundCard>
          <h1>Har vi missat något?</h1>
          <AnchorButton path={ROUTES.ABOUT_US}>
            Kontakta oss <FontAwesomeIcon icon={faArrowRight} />
          </AnchorButton>
        </DarkBackgroundCard>
      </div>
    </div>
  );
};
