import { InformationBox } from "Components/Common/InformationBox";


interface Props { }

export const IntroductionSection: React.FC<Props> = ({ }): JSX.Element => {
  return (
    <InformationBox
      header="Vilka är mina rättigheter om ett tåg blir försenat?"
      subHeader="Om du vill ha en återbetalning eller kompensation för en försening på en
        resa du redan har gjort, varierar reglerna för hur mycket du kan få
        tillbaka beroende på förseningens längd, samt den totala sträckan i
        kilometer för tågresan, inte bara den sträcka du reste på."
    />
  );
};
