import React, { useEffect, useState } from 'react';
import { Divider } from "@mui/material";
import FormQuestion from "Components/Misc/FormQuestion";
import ButtonLoadAndConfirm from "Components/Common/Buttons/ButtonLoadAndConfirm";
import { createUseStyles, Theme } from "react-jss";

interface Props {}

interface ErrorPopUpProps {
	error: string;
}

interface SuccessPopUpProps {
	message: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto',
		padding: '20px',
		borderRadius: '12px',
		backgroundColor: '#f9f9f9',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	},
	section: {
		marginBottom: '20px',
	},
	divider: {
		margin: '20px 0',
	},
	buttonContainer: {
		textAlign: 'center',
		padding: '20px 0',
	},
	button: {
		borderRadius: '9999px',
		padding: '1rem 2.5rem',
		fontWeight: 'bold',
		cursor: 'pointer',
		transition: 'background-color 200ms ease',
		'&:hover': {
			backgroundColor: theme.colors.buttonHover,
		},
		'&:disabled': {
			cursor: 'not-allowed',
			opacity: 0.6,
		},
	},
	errorPopUp: {
		backgroundColor: 'gray',
		padding: '1rem',
		borderRadius: '8px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		marginBottom: '10px',
		marginTop: '10px',
	},
	successPopUp: {
		backgroundColor: theme.colors.buttonHover,
		padding: '1rem',
		borderRadius: '8px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		marginBottom: '10px',
		marginTop: '10px',
	},
}));

const ErrorPopUp: React.FC<ErrorPopUpProps> = ({ error }) => {
	const classes = useStyles();
	return (
		<div className={classes.errorPopUp}>
		<h1>Tyvärr är du inte berättigad till betalning</h1>
		<p>{error}</p>
		</div>
	);
};

const SuccessPopUp: React.FC<SuccessPopUpProps> = ({ message }) => {
	const classes = useStyles();
	return (
		<div className={classes.successPopUp}>
		<h3>Det verkar som att du har rätt till kompensation!!</h3>
		<p>{message}</p>
		</div>
	);
};

const CompensationForm: React.FC<Props> = () => {
	const classes = useStyles();
	const [loadingAnswer, setLoadingAnswer] = useState(false);
	const [answer1, setAnswer1] = useState<string | null>(null);
	const [answer2, setAnswer2] = useState<string | null>(null);
	const [answer3, setAnswer3] = useState<string | null>(null);
	const [showSuccess, setShowSuccess] = useState(false);
	
	useEffect(() => {
		if (loadingAnswer) {
			setTimeout(() => {
				setLoadingAnswer(false);
				if (answer1 === "Ja" && answer2 === "Ja" && answer3 === "Nej") {
					setShowSuccess(true);
				}
			}, 1000);
		}
	}, [loadingAnswer, answer1, answer2, answer3]);
	
	return (
		<div className={classes.formContainer}>
			<div className={classes.section}>
				<h1>Har jag rätt till återbetalning?</h1>
				<p style={{ fontWeight: '500' }}>Svara på tre frågor och se om du har rätt att få pengarna tillbaka?</p>
				<Divider className={classes.divider} />
	
				<FormQuestion label="Har du åkt långdistanståg de senaste 3 månaderna?" onChange={setAnswer1} />
				{answer1 === "Nej" && <ErrorPopUp error="Tyvärr är du inte berättigad" />}
				<Divider className={classes.divider} />
	
				<FormQuestion label="Var ditt tåg mer än 1 timme försenat?" onChange={setAnswer2} />
				{answer2 === "Nej" && <ErrorPopUp error="Tyvärr är du inte berättigad" />}
				<Divider className={classes.divider} />
	
				<FormQuestion label="Har du redan krävt kompensation för resan?" onChange={setAnswer3} />
				{answer3 === "Ja" && <ErrorPopUp error="Du har redan krävt kompensation" />}
				{showSuccess && <SuccessPopUp message="Vi betalar i snitt ut 413kr" />}
			</div>
			<div className={classes.buttonContainer}>
				<ButtonLoadAndConfirm
					buttonStyle={classes.button}
					text={"Sök Ersättning"}
					disabled={!answer1 || !answer2 || !answer3 || answer1 === "Nej" || answer2 === "Nej" || answer3 === "Ja"}
					loading={loadingAnswer}
					onClick={() => setLoadingAnswer(true)}
				/>
			</div>
		</div>
	);
};

export default CompensationForm;
