// eslint-disable-next-line no-restricted-globals
export const isLocalhost = Boolean(location.hostname === "localhost" || location.hostname === "127.0.0.1")

export const requestApi = async (path = "", method = "GET", data = null, token = "", headers = {}, endPointUrl = "") => {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  let url = `${endPointUrl}${path}`;

  let headerObj: { [key: string]: string } = { "Content-Type": "application/json" };

  if (token) {
    headerObj["Authorization"] = `Bearer ${token}`;
  }

  headers = Object.assign(headerObj, headers);

  const response = await fetch(url, {
    method: method.toUpperCase(),
    mode: "cors",
    cache: "no-cache",
    headers,
    body: data && method.toUpperCase() !== "GET" ? JSON.stringify(data) : null,
  });

  try {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(JSON.stringify(response));
    }
  } catch (error) {
    console.error(error);
  }

  return await response.json();
};
