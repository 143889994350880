import { MainInfoSection } from "./childComponents/MainInfoSection";
import { IntroductionSection } from "./childComponents/IntroductionSection";
import { RightsBannerSection } from "./childComponents/RightsBanner";
import { QuestionsSection } from "./childComponents/QuestionsSection";
import { ProcessSection } from "./childComponents/ProcessSection";

export const MyRights: React.FC<{}> = ({}): JSX.Element => {
  return (
    <div>
      <RightsBannerSection />
      <IntroductionSection />
      <MainInfoSection />
      <ProcessSection />
      <QuestionsSection />
    </div>
  );
};
