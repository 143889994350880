import { createUseStyles, Theme } from "react-jss";


const useStyles = createUseStyles((_theme: Theme) => ({
  small: {
    height: '8px',
  },
  medium: {
    height: '16px',
  },
  large: {
    height: '32px',
  },
  xlarge: {
    height: '64px',
  },
  xxlarge: {
    height: '84px',
  },
}));

interface Props {
  type: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
}

const Space = (props: Props) => {
  const classes = useStyles();

  return <div className={classes[props.type]}></div>;
};

export default Space;
