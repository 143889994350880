import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InformationBox } from "Components/Common/InformationBox";
import { Theme } from "react-jss";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  infoIcon: {
    height: "20px",
    marginRight: "10px"
  },
  infoText: {
    fontSize: "1.5rem",
    fontWeight: "bold"
  },
}));


interface Props { }

export const Compensation: React.FC<Props> = ({ }): JSX.Element => {
  const classes = useStyles();

  return (
    <InformationBox
      header="Min ersättning"
      subHeader="Din utbetalda kompensation"
      children={
        <>
          <FontAwesomeIcon icon={faCoins} className={classes.infoIcon} />
          <span className={classes.infoText}>0kr</span>
        </>
      }
    />
  );
};
