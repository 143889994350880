import { faHandshakeAngle, faSpinner } from "@fortawesome/free-solid-svg-icons"; // Import spinner icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon component
import { BannerFooter } from "Components/Common/BannerFooter";
import React, { useState } from "react";
import { createUseStyles, Theme } from "react-jss";
import bannerVideo from "assets/videos/banner.mp4";
import { useStylesLogin } from "pages/login/Login";
import toast from "react-hot-toast";
import { KlimraTheme } from "styles/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  bannerContainer: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colors.textSecondary,
    textAlign: "center",
    padding: "20px",
    boxSizing: "border-box",
    overflow: "hidden",
    "@media (max-width: 670px)": {
      padding: "5px",
    },
  },
  videoBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  tintOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 0,
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "90%",
    marginBottom: "4rem",
    zIndex: 1,
    "@media (max-width: 670px)": {
      width: "100%",
      maxWidth: "100%",
    },
  },
  bannerText: {
    fontSize: "3.75rem",
    fontWeight: "bold",
    marginTop: "150px",
    marginBottom: "0.5rem",
    width: "100%",
    "@media (max-width: 670px)": {
      fontSize: "10vw",
    },
  },
  bannerSubText: {
    fontSize: "1.5rem",
    marginBottom: "1rem",
    padding: "0 20px",
    "@media (max-width: 670px)": {
      fontSize: "6vw",
    },
  },
  buttonWrapper: {
    marginTop: "2rem",
  },
  button: {
    padding: "1.4rem 2.5rem",
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.colors.textPrimary,
    backgroundColor: theme.colors.primary,
    border: "none",
    borderRadius: "25px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      backgroundColor: theme.colors.buttonHover,
    },
    "@media (max-width: 400px)": {
      padding: "1rem 1.6rem",
      fontSize: "0.8rem",
      width: "auto",
    },
  },
  inputField: {
    width: '80%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    "@media (max-width: 550px)": {
      width: '90%',
    },
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: theme.colors.secondary,
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    width: "200px",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    "@media (max-width: 400px)": {
      fontSize: '0.9rem',
      padding: '8px 16px',
    },
    "&:hover": {
      backgroundColor: "#8cb5b6",
    }
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '15px',
    marginBottom: '20px',
    "@media (max-width: 400px)": {
      gap: '10px',
    },
  },
}));

interface Props {}

export const Investor: React.FC<Props> = (): JSX.Element => {
  const classes = useStyles();
  const loginClasses = useStylesLogin();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the submission starts

    try {
      const response = await fetch('https://kfx7920r58.execute-api.eu-north-1.amazonaws.com/main/store-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email }),
      });

      if (response.ok) {
        console.log('Submitted successfully');
        toast.success(
          "Saved...",
          {
            style: {
              borderRadius: "10px",
              background: KlimraTheme.colors.primary,
              color: KlimraTheme.colors.textPrimary,
            },
            duration: 2000,
          }
        );
        setName('');
        setEmail('');
      } else {
        console.error('Failed to submit');
      }
    } catch (error) {
      console.error('Error during submission:', error);
    } finally {
      setLoading(false); // Set loading to false after the submission ends
    }
  };

  return (
    <div className={classes.bannerContainer}>
      <video className={classes.videoBackground} src={bannerVideo} autoPlay loop muted />
      <div className={classes.tintOverlay}></div>
      <div className={classes.content}>
        <h1 className={classes.bannerText}>Are you an Investor?</h1>
        <p className={classes.bannerSubText}>
          Enter your email and name to receive follow-up information and stay connected after our meeting. We look forward to continuing our conversation and sharing more about our opportunities.
        </p>
        <div className={loginClasses.container}>
          <h1 className={loginClasses.title}>Your Information</h1>
          <form onSubmit={handleSubmit} className={classes.inputContainer}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classes.inputField}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.inputField}
              required
            />
            <button type="submit" className={classes.submitButton}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin /> // Show spinner icon while loading
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
      </div>
      <BannerFooter
        icon={faHandshakeAngle}
        text={"I samarbete med SSE Business Lab"}
      />
    </div>
  );
};