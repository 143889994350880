import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { createUseStyles } from 'react-jss';
import { Theme } from '@mui/material';

const useStyles = createUseStyles((_theme: Theme) => ({
	modalStyle: {
		backgroundColor: 'white',
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: '80vh',
		width: "80%",
		boxShadow: 24,
		p: 4,
		overflow: 'auto',
		padding: '3rem',
		maxWidth: '1000px',
		borderRadius: '20px',
	},
	container: {
		textAlign: 'center',
		listStylePosition: 'inside',
		display: 'flex',
		justifyContent: 'center'
	},
}));

interface BasicModalProps {
	ButtonComponent: React.FC<{ onClick: () => void }>;
	children?: React.ReactNode;
	showCloseButton?: boolean;
	CloseButtonComponent?: React.FC<{ onClick: () => void }>;
}
const DefaultClosedButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	return (
		<button onClick={onClick}>
			Stäng
		</button>
	);
};

const KlimraModal: React.FC<BasicModalProps> = ({ CloseButtonComponent = DefaultClosedButton, showCloseButton = true, ButtonComponent, children }) => {
	const classes = useStyles();
	
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	
	return (
		<div>
			<ButtonComponent onClick={handleOpen} />
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<div className={classes.container}>
				<Box className={classes.modalStyle}>
					{children}
					{showCloseButton && <CloseButtonComponent onClick={handleClose} />}
				</Box>
			</div>
			</Modal>
		</div>
	);
};

export default KlimraModal;