import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { DarkBackgroundCard } from "Components/Common/DarkBackgroundCard";
import { ROUTES } from "constants/routes";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  faqBottomContainer: {
    padding: "5rem 15vmin",
    "@media (max-width: 550px)": {
      padding: "5rem 5vmin",
    },
  },
  buttonClass: {
    fontSize: "1rem",
    width: "fit-content",
    whiteSpace: "nowrap",
    marginLeft: "auto",
    "@media (max-width: 768px)": {
      margin: "0 auto",
    },
  },
}));

interface Props {}

export const FaqBottomSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.faqBottomContainer}>
      <DarkBackgroundCard>
        <h1>Hittar du inte svar på dina frågor?</h1>
        <AnchorButton path={ROUTES.ABOUT_US} addedClassNames={classes.buttonClass} centerAlign={false} >
          Kontakta oss <FontAwesomeIcon icon={faArrowRight} />
        </AnchorButton>
      </DarkBackgroundCard>
    </div>
  );
};
