import React from "react";
import { createRoot } from "react-dom/client";
import { isLocalhost } from "./api";
import { App } from "App";
import { ThemeProvider } from "react-jss";
import { KlimraTheme } from "styles/theme";
import "./styles/index.css";
import { Toaster } from "react-hot-toast";
import {CookiesProvider} from "react-cookie";


const container = document.getElementById("root");
const root = createRoot(container!);

if (isLocalhost) {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={KlimraTheme}>
        <Toaster />
          <CookiesProvider defaultSetOptions={{path: '/'}}>
              <App />
          </CookiesProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
} else {
  root.render(
    <ThemeProvider theme={KlimraTheme}>
      <Toaster />
        <CookiesProvider defaultSetOptions={{path: '/'}}>
            <App />
        </CookiesProvider>
    </ThemeProvider>
  );
}
