import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "70%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "0.9rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {}

export const Policy: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection}>
      <article>
        <h1 style={{ textAlign: "center" }}>PRIVACY NOTICE For Klimra</h1>
        <h2>Kära användare,</h2>
        <p>
          Vårt företag tillhandahåller en omfattande tjänst för att hjälpa
          kunder att få ersättning för tågförseningar. Vi tar hand om hela
          ersättningsprocessen och ser till att våra kunder får snabb och
          rättvis ersättning utan krångel med manuella krav.
          <br />
          <br />
          Genom att ge oss tillgång till din post kan vi bekräfta vilket tåg du
          är på genom en algoritm som söker efter tågbiljetter. När vi får
          bekräftat att tåget är försenat inleder vi automatiskt processen för
          att begära ersättning för kundens räkning genom att skicka ett
          officiellt e-postmeddelande till tågbolaget från vårt företagsnamn.
          Detta inkluderar att förbereda och skicka in all nödvändig
          dokumentation och följa upp tills ersättningen har beviljats.
          <br />
          <br />
          Vår tjänst är utformad för att vara effektiv och användarvänlig,
          vilket sparar våra kunder tid och ansträngning samtidigt som
          sannolikheten för ett framgångsrikt krav ökar. Kunderna hålls
          informerade i varje skede av processen och inga åtgärder vidtas utan
          deras uttryckliga godkännande. Vi arbetar med de högsta standarderna
          för datasäkerhet och integritet och ser till att all person- och
          reseinformation hanteras säkert och i enlighet med relevanta
          bestämmelser.
          <br />
          <br />
          Dessa tjänster tillhandahålls på Klimra AB (https://www.klimra.com),
          som tillhandahålls av Klimra AB, organisationsnummer 559490-0986 med
          registrerad adress Drömstigen 41.
          <br />
          <br />
          Din integritet är viktig för oss. Enligt den allmänna
          dataskyddsförordningen (2016/679) är vi ansvariga för behandlingen av
          personuppgifter för vilka vi bestämmer ändamålen och medlen för
          behandlingen. Med "personuppgifter" menar vi information som direkt
          eller indirekt kan hänföras till en fysisk levande person och som
          behandlas i samband med Tjänsterna.
          <br />
          <br />
          Detta dokument innehåller information om vår insamling, användning och
          behandling av personuppgifter, vilka parter som vi kan komma att dela
          sådana uppgifter med och dina rättigheter i förhållande till
          personuppgifter om dig. När du använder Tjänsterna kommer vi att
          behandla personuppgifter för ett antal syften, enligt vad som anges
          nedan.
          <br />
          <br />
          Vi använder tredjepartsleverantörer för att hjälpa oss att driva vår
          webbplats och leverera våra tjänster på ett effektivt sätt. Vår
          webbplats är hostad av Amazon Web Services (AWS), en tredjeparts
          hostingtjänst. Det innebär att de filer och data som vår webbplats
          består av lagras på AWS:s servrar och att de underlättar leveransen av
          våra webbsidor till din webbläsare. Som sådan kan AWS ha tillgång till
          alla uppgifter som överförs via vår webbplats, inklusive
          personuppgifter.
          <br />
          <br />
          Vi råder dig att läsa igenom AWS integritetspolicy och användarvillkor
          för att förstå hur de hanterar dina personuppgifter. Du kan hitta
          deras integritetspolicy här och deras användarvillkor här. Dessutom
          använder vi din webbläsares lokala lagring för att hålla dig inloggad
          med uppdaterings- och åtkomsttokens.
        </p>
      </article>
      <article>
        <h2>
          <strong>Google API:</strong>
        </h2>
        <p>
          För att automatisera ersättningsprocessen behöver vi åtkomst till din
          e-postinkorg. Efter att du har kopplat din e-post till vår
          applikation, kommer vi att spara "tokens" i en databas för att senare
          kunna söka efter biljetter i din inkorg. I vissa fall kommer vi även
          skicka ut ersättningsärenden från din mejl. Om du vill koppla bort en
          e-postadress kan du göra detta genom att gå till Mina sidor och klicka
          på fliken E-post. Om du tar bort en e-postadress kommer alla "tokens"
          att bli ogiltiga och raderas från vår databas. Om du raderar ditt
          konto kommer även alla e-postkopplingar att tas bort och
          ogiltigförklaras.
        </p>
      </article>
      <article>
        <h2>
          <strong>DATASKYDD OCH SÄKERHET</strong>
        </h2>
        <p>
          <strong>Kryptering: </strong> All känslig data som överförs mellan din
          webbläsare och våra servrar är krypterad med hjälp av
          industristandardens Secure Sockets Layer (SSL)-teknologi. Detta
          säkerställer att all information som skickas eller tas emot är skyddad
          från obehörig åtkomst.
          <br />
          <strong>Åtkomstkontroller: </strong> Åtkomst till känslig data är
          begränsad till auktoriserad personal. Vi använder robusta
          åtkomstkontrollmekanismer för att säkerställa att endast personer med
          ett legitimt behov av att få åtkomst till din data kan göra det.
          <br />
          <strong>Dataanonymisering och Pseudonymisering: </strong> Där det är
          möjligt anonymiserar eller pseudonymiserar vi data för att ytterligare
          skydda din integritet. Detta innebär att data ändras på ett sätt som
          gör att den inte kan spåras tillbaka till dig utan ytterligare
          information.
          <br />
          <strong>Dataminimering: </strong> Vi samlar endast in den minsta mängd
          personuppgifter som är nödvändig för att tillhandahålla våra tjänster.
          Genom att begränsa den data vi samlar in minskar vi risken för
          obehörig åtkomst och exponering.
          <br />
          <strong>Datalagring och Radering: </strong>Vi behåller personuppgifter
          endast så länge det är nödvändigt för att uppfylla de syften för vilka
          de samlades in, eller enligt vad som krävs enligt lag. När data inte
          längre behövs ser vi till att den raderas eller anonymiseras på ett
          säkert sätt.
        </p>
      </article>

      <article>
        <h2>
          <strong>BEHANDLANDE PERSONUPPGIFTER:</strong>
        </h2>
        <p>
          Vi samlar in och använder följande typer av personuppgifter för att
          leverera och upprätthålla effektiva och säkra Tjänster, för de ändamål
          som anges nedan:
          <br />
          <br />
          (a) Namn;
          <br />
          (b) E-postadress;
          <br />
          (c) Telefonnummer;
          <br />
          (d) IP-adress;
          <br />
          (e) personnummer;
          <br />
          <br />
          <br />
          Observera att vi inte kan tillhandahålla Tjänsterna utan att behandla
          ovan angivna personuppgifter. När du använder Tjänsterna kommer viss
          information att samlas in automatiskt, inklusive information om din
          användning av vår tjänst
        </p>
      </article>

      <article>
        <h2>
          <strong>ÄNDAMÅL MED BEHANDLINGEN OCH RÄTTSLIGA GRUNDER</strong>
        </h2>
        <p>
          Vi kommer att behandla den information som anges ovan för följande
          syften:
          <br />
          <br />
          (a) För att administrera ditt konto, för att möjliggöra och
          tillhandahålla tjänsterna och integration med tredjepartstjänster samt
          för att i övrigt tillhandahålla tjänsterna enligt användarvillkoren.
          Behandlingen av personuppgifter för detta ändamål är baserad på
          nödvändigheten för att fullgöra vårt avtal med dig.
          <br />
          <br />
          (b) För att skicka dig aviseringar eller meddelanden via e-post eller
          på annat sätt, inklusive för att förse dig med marknadsföring av våra
          och våra närståendes produkter och tjänster baserat på vårt
          berättigade intresse av att skicka dig reklammaterial om produkter och
          tjänster som du kan vara intresserad av
          <br />
          <br />
          (c) För att informera dig om uppdateringar av tjänsterna eller
          användarvillkoren baserat på vårt berättigade intresse att hålla dig
          uppdaterad om utvecklingen av tjänsterna, såsom nya funktioner och de
          villkor som du samtyckt till att använda tjänsterna under.
          <br />
          <br />
          (d) För att förbättra och utveckla tjänsterna eller nya tjänster och
          produkter och för att analysera din användning av tjänsterna på
          grundval av vårt berättigade intresse av att erbjuda dig uppdaterade
          tjänster baserat på din användning av tjänsterna, och för att utveckla
          nya produkter och tjänster som tillgodoser dina preferenser och ditt
          samtycke till vår användning av cookies
          <br />
          <br />
          (e) För att säkerställa att tjänsterna fungerar tekniskt och för att
          förhindra användning av tjänsterna i strid med användarvillkoren för
          att kunna fullgöra vårt avtal med dig och baserat på vårt berättigade
          intresse att säkerställa att våra tjänster inte missbrukas för att
          skydda oss själva och våra andra användare
          <br />
          <br />
          (f) För att genomdriva användarvillkoren, inklusive för att skydda
          våra rättigheter, egendom och säkerhet och även tredje parts
          rättigheter, egendom och säkerhet om det är nödvändigt på grundval av
          vårt legitima intresse av att skydda vår verksamhet, våra användare av
          tjänsterna och andra tredje parter; och
          <br />
          <br />
          (g) För att uppfylla krav enligt lag, baserat på våra skyldigheter att
          följa sådana lagar.
          <br />
          <br />
          <br />
          För behandlingsaktiviteter som baseras på ett berättigat intresse har
          vi noggrant vägt detta berättigade intresse mot din rätt till
          integritet och kommit fram till att vårt intresse kan utövas utan att
          inkräkta på dina rättigheter och friheter på ett sätt som förbjuder
          behandling av personuppgifter om dig.
        </p>
      </article>

      <article>
        <h2>
          <strong>LAGRING AV PERSONUPPGIFTER</strong>
        </h2>
        <p>
          Som huvudregel lagrar vi dina personuppgifter endast så länge som det
          är nödvändigt för att utföra Tjänsterna, såvida det inte finns en
          rättslig skyldighet att lagra uppgifterna längre.
          <br />
          <br />
          Efter att du har avslutat ditt konto kommer personuppgifter kopplade
          till kontot att lagras i högst 90 dagar.
          <br />
          <br />
          Personuppgifter som behandlas med stöd av en rättslig förpliktelse
          lagras så länge som den rättsliga förpliktelsen motiverar att
          personuppgifterna lagras. Detta inkluderar när personuppgifter
          behandlas för bokföringsändamål, där kontouppgifterna lagras under
          innevarande år och fram till och med det sjunde året efter utgången av
          det kalenderår då räkenskapsåret avslutades.
        </p>
      </article>

      <article>
        <h2>
          <strong>COOKIES, PIXLAR OCH ANNAN SYSTEMTEKNIK</strong>
        </h2>
        <p>
          Vi samlar in information genom teknik som cookies, pixlar och lokal
          lagring (till exempel i din webbläsare eller på din enhet). För
          information om hur vi använder systemteknik och analysverktyg och hur
          du kan förhindra användningen av cookies, se vårt Cookiemeddelande
          (tillgängligt här [https://klimra.com/cookies]).{" "}
        </p>
      </article>

      <article>
        <h2>
          <strong>SVARA PÅ RÄTTSLIGA FÖRFRÅGNINGAR OCH FÖRHINDRA SKADA</strong>
        </h2>
        <p>
          Vi kan få tillgång till, bevara och dela din information som svar på
          en rättslig begäran (som en husrannsakan, domstolsbeslut eller en
          stämning eller liknande), eller när det är nödvändigt för att
          upptäcka, förhindra och hantera bedrägerier och annan olaglig
          verksamhet, för att skydda oss själva, dig och andra användare,
          inklusive som en del av utredningar, om vi har en god tro att
          tillämplig lag kräver att vi gör det.
          <br />
          <br />
          Detta kan inkludera att svara på juridiska förfrågningar från
          jurisdiktioner utanför EU/EES där vi har en god tro att svaret krävs
          enligt lag i den jurisdiktionen, påverkar användare i den
          jurisdiktionen och är förenligt med internationellt erkända
          standarder.
          <br />
          <br />
          Information som vi får om dig på Webbsidan genom att använda våra
          Tjänster kan komma att nås, bevaras och lagras under en längre
          tidsperiod när den är föremål för en rättslig begäran eller
          skyldighet, myndighetsutredning eller utredning om eventuella
          överträdelser av våra användarvillkor eller policyer, eller på annat
          sätt för att förhindra skada.
          <br />
        </p>
      </article>

      <article>
        <h2>
          <strong>NÅGRA ANDRA SAKER DU BEHÖVER VETA</strong>
        </h2>
        <p>
          <strong>Länkar till andra webbsidor</strong>
        </p>
        <p>
          Du bör vara medveten om att när du befinner dig på vår webbsida kan du
          komma att hänvisas till andra webbplatser som drivs av tredje part där
          den personliga information som samlas in ligger utanför vår kontroll.
          Sekretessmeddelandet för den nya webbplatsen kommer att styra den
          information som erhålls från dig på den webbplatsen.
        </p>

        <p>
          <strong>Förändring av kontroll</strong>
        </p>
        <p>
          Om ägandet av vår verksamhet förändras kan vi komma att överföra din
          information till de nya ägarna så att de kan fortsätta med tjänsterna.
          Den nya ägaren kommer fortfarande att behöva uppfylla de åtaganden vi
          har gjort i detta integritetsmeddelande.
        </p>

        <p>
          <strong>Meddelande om ändringar</strong>
        </p>
        <p>
          Om vi gör ändringar i detta integritetsmeddelande kommer vi att
          meddela dig via e-post. Om ändringarna är väsentliga kommer vi att ge
          dig ytterligare, framträdande meddelande som är lämpligt med hänsyn
          till omständigheterna och, om så krävs enligt tillämplig lag, be om
          ditt samtycke.
        </p>
      </article>

      <article>
        <h2>
          <strong>Dina integritetsrättigheter enligt EU-lagstiftningen</strong>
        </h2>
        <p>
          Du har en absolut rätt att invända mot behandling av dina
          personuppgifter för direktmarknadsföring. Du har också rätt att
          återkalla ditt tidigare lämnade samtycke. Återkallandet av ditt
          samtycke påverkar inte lagligheten av den behandling som baserades på
          samtycket innan det återkallades, och vi kan fortsätta att behandla
          dina personuppgifter baserat på andra rättsliga grunder, med undantag
          för direktmarknadsföring.
          <br />
          <br />
          Du har rätt att begära tillgång till och ytterligare information om
          behandlingen av dina personuppgifter, eller begära att vi korrigerar,
          rättar, kompletterar, raderar eller begränsar behandlingen av dina
          personuppgifter. Du har rätt att kostnadsfritt en (1) gång per
          kalenderår få en kopia av de personuppgifter som vi behandlar om dig.
          För eventuella ytterligare kopior som du begär kan vi komma att ta ut
          en rimlig avgift baserad på administrativa kostnader.
          <br />
          <br />
          Om behandlingen grundar sig på den rättsliga grunden berättigat eller
          allmänt intresse har du rätt att invända mot behandlingen. Om du gör
          detta kommer vi inte längre att få behandla personuppgifterna om vi
          inte kan visa tvingande skäl till varför våra berättigade skäl för
          behandlingen väger tyngre än dina intressen, rättigheter och friheter
          - såvida inte behandlingen sker för att fastställa, göra gällande
          eller försvara rättsliga anspråk.
          <br />
          <br />
          Om behandlingen grundar sig på den rättsliga grunden samtycke eller
          fullgörande av avtal har du rätt till dataportabilitet.
          Dataportabilitet innebär att du kan få ut de personuppgifter som du
          har lämnat till oss i ett strukturerat, allmänt använt och
          maskinläsbart format och har rätt att överföra sådana uppgifter till
          en annan personuppgiftsansvarig.
          <br />
          <br />
          För att utöva ovan nämnda rättigheter, eller om du har några frågor om
          vår delningspraxis, dina rättigheter enligt EU-lagstiftningen eller
          vill att dina personuppgifter ska tas bort, vänligen kontakta oss på
          följande adress: Contact@klimra.com eller Klimra AB, Drömstigen 41.
          För att säkerställa att du får ett snabbt svar, vänligen ange ditt
          fullständiga namn och, om tillämpligt, din adress, användarnamn och
          den e-postadress som används för registrering. Observera att du själv
          bör underteckna begäran om att få information om behandlingen av dina
          personuppgifter.
          <br />
          <br />
          Om du har några klagomål angående vår behandling av dina
          personuppgifter kan du lämna in ett klagomål till den behöriga
          dataskyddsmyndigheten. Du kan läsa mer om de lokala
          dataskyddsmyndigheterna under följande länk
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        </p>
      </article>

      <article>
        <h2>
          <strong>Kontakta vårt dataskyddsombud</strong>
        </h2>
        <p>
          Om du vill kontakta vårt dataskyddsombud kan du göra det på
          Pontus.thybell@gmail.com
        </p>
      </article>
    </section>
  );
};
