import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { InfoSlideWithImage } from "Components/Common/InfoSlideWithImage";
import { ROUTES } from "constants/routes";
import SlideImage from "assets/images/home/person-smiling-on-train.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {}

export const ProcessSection: React.FC<Props> = ({}): JSX.Element => {
  return (
    <InfoSlideWithImage
      title="Hur Klimra Gör Denna Process Enkel?"
      imageSrc={SlideImage}
      imageAlt="Smiling person walking next to train looking at their phone"
    >
      <p>
        Klimra förenklar hela denna process genom att hantera alla dina
        kompensationskrav för försenade tåg. Du behöver inte längre navigera
        genom komplicerade regler eller hantera olika tågbolag; Klimra sköter
        allt åt dig. Vi ser till att du får den kompensation du har rätt till
        snabbt och utan krångel, vilket gör din reseupplevelse smidigare och mer
        bekväm.
      </p>
      <br />
      <AnchorButton path={ROUTES.ABOUT_US}>
        Kräv kompensation <FontAwesomeIcon icon={faArrowRight} />
      </AnchorButton>
    </InfoSlideWithImage>
  );
};
