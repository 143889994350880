import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  informationSection: {
    padding: "5rem",
    marginInline: "auto",
    width: "50%",
    "& article": {
      marginBlock: "2rem",
      fontSize: "1rem",
    },
    "& h1": {
      fontSize: "2rem",
    },
    "& h2": {
      fontSize: "1.2rem",
      fontWeight: "normal",
    },
    "@media (max-width: 1300px)": {
      width: "70%",
    },
    "@media (max-width: 800px)": {
      padding: "2rem",
      width: "80%",
      "& article": {
        fontSize: "1.1rem",
      },
      "& h1": {
        fontSize: "1.5rem",
      },
      "& h2": {
        fontSize: "1rem",
      },
    },
  },
}));

interface Props {}

export const MainInfoSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.informationSection}>
      <article>
        <h1>
          Du kan ha laglig rätt att få tillbaka pengar för dina försenade tåg.
        </h1>
        <p>
          <strong>För sträckor på 150 kilometer eller mer </strong>
          har du rätt till en återbetalning på 25 procent efter en försening på
          60 minuter och 50 procent efter 120 minuter.
          <br />
          <br />
          <strong>För sträckor under 150 kilometer </strong>
          har du rätt till en återbetalning på 50 procent efter 20 minuter, 75
          procent efter 40 minuter och 100 procent efter 60 minuter.
          <br />
          <br />
          <strong>Efter 60 minuter </strong>
          kan du välja att fortsätta din resa eller bli ombokad till nästa
          tillgängliga avgång så snart som möjligt (detta kan vara med ett annat
          transportmedel, som en ersättningsbuss) eller omboka kostnadsfritt
          till ett senare datum. Om du väljer något av dessa alternativ avstår
          du från din rätt att få biljetten återbetalad.
          <br />
          <br />
          Om du har en biljett för en viss tidsperiod, som ett månadskort, kan
          du vanligtvis också få kompensation för detta. I så fall behöver du
          kontakta resebolaget, fråga dem hur mycket resan skulle ha kostat med
          en vanlig biljett och ansöka om kompensation som om du hade köpt en
          enkelbiljett.
          <br />
          <br />
          Om du måste ordna din egen alternativa resa - som att köra själv eller
          ta en taxi - på grund av en inställd eller försenad tjänst, kan du
          också ansöka om kompensation. Du måste dock visa att du försökt hålla
          kostnaderna nere så mycket som möjligt och kan få en maximal
          ersättning på 1 432 kronor.
          <br />
          <br />
          Om du redan visste om en försening eller inställning innan du köpte en
          biljett, oavsett om det är för tåg, buss eller annan typ av
          kollektivtrafik, har du ingen rätt till kompensation.
        </p>
      </article>
      <article>
        <h1>
          Vad kan jag göra om mitt tåg är inställt och det inte finns någon
          ersättningsbuss?
        </h1>
        <p>
          Om du står utan något annat sätt att ta dig till din destination - som
          när ditt tåg är inställt och det inte finns någon ersättningsbuss -
          bör du kunna välja mellan att få en återbetalning eller att omboka din
          biljett till en annan avgång kostnadsfritt.
          <br />
          <br />
          Om det inte är möjligt, bör ditt tågbolag hjälpa dig att komma till
          din destination genom att ordna annan transport eller täcka
          boendekostnader om du blir strandsatt utan någonstans att bo.
          Vanligtvis täcker detta också ersättning för taxi eller för att
          använda din egen bil. Reglerna varierar beroende på tågbolag. Till
          exempel erbjuder SJ en ersättning på upp till 3000 kronor som kan
          användas för alternativ transport eller boende. Vy uppger på sin
          webbplats att kundservice kan hjälpa dig att nå din destination genom
          att boka alternativ resa.
        </p>
      </article>
    </section>
  );
};
