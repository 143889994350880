import { PropsWithChildren } from "react";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.backgroundPrimary,
    textAlign: "center",
    padding: "5rem 20vmin",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    alignItems: "center",
  },
}));

interface Props {}

export const CenteredContentBox: React.FC<PropsWithChildren<Props>> = ({
  children,
}): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};
