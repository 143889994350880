import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import { ROUTES } from "constants/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { DarkBackgroundCard } from "Components/Common/DarkBackgroundCard";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((_theme: Theme) => ({
  buttonClass: {
    "@media (max-width: 1100px)": {
      fontSize: "0.8rem",
      width: "fit-content",
      whiteSpace: "nowrap",
    },
  },
  container: {
    margin: "100px 200px",
  },
}));

interface Props {}

export const AdditionalQuizSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <DarkBackgroundCard>
      <h1>Har du några frågor?</h1>
      <AnchorButton path={ROUTES.ABOUT_US} addedClassNames={classes.buttonClass}>
        Kontakta oss <FontAwesomeIcon icon={faArrowRight} />
      </AnchorButton>
    </DarkBackgroundCard>
    </div>
    
  );
};
