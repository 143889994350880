import { ROUTES } from "constants/routes";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useButtonStyle } from "./buttonStyle";

type AnchorButtonProps = {
  path: (typeof ROUTES)[keyof typeof ROUTES];
  icon?: IconDefinition;
  buttonWidth?: string;
  addedClassNames?: string;
  centerAlign?: boolean;
};

export const AnchorButton: React.FC<PropsWithChildren<AnchorButtonProps>> = ({ icon, path, children, addedClassNames, centerAlign=true }): JSX.Element => {
  const classes = useButtonStyle({ centerAlign });
  const navigate = useNavigate();

  return (
    <button
      className={`${classes.anchorButton} ${addedClassNames}`}
      onClick={() => navigate(path)}
      aria-label={`navigate-${path}`}
      type="button"
    >
      {children}
      {icon && <FontAwesomeIcon className={classes.icon} icon={icon} />}
    </button>
  );
};
