import { AnchorButton } from "Components/Common/Buttons/AnchorButton";
import image from "assets/images/home/person-smiling-on-train.jpg";
import { ROUTES } from "constants/routes";
import { InfoSlideWithImage } from "Components/Common/InfoSlideWithImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { createUseStyles, Theme } from "react-jss";


const useStyles = createUseStyles((_theme: Theme) => ({
  navButton: {
    "@media (max-width: 400px)": {
      fontSize: "0.8rem",
    },
  },
}));

interface Props {}

export const BenefitsSection: React.FC<Props> = ({}): JSX.Element => {
  const classes = useStyles();

  return (
    <InfoSlideWithImage
      title="Fördelarna med att ha ett konto hos Klimra"
      imageAlt="Person smiling on a train with their phone in hand."
      imageSrc={image}
    >
      <p>
        Fördelarna med ett Klimra-konto är många, och våra kunder är väldigt
        nöjda. Att använda Klimra innebär:
      </p>
      <ul>
        <li>Ersättning för resor upp till tre månader tillbaka.</li>
        <li>Du behåller pengarna oavsett utfall.</li>
        <li>Du involveras inte i ärendet.</li>
        <li>Vi hanterar dina framtida resor</li>
      </ul>
      <AnchorButton path={ROUTES.REGISTER} addedClassNames={classes.navButton}>
        Kräv kompensation <FontAwesomeIcon icon={faArrowRight} />
      </AnchorButton>
    </InfoSlideWithImage>
  );
};
