import { createUseStyles, Theme } from "react-jss";
import { PropsWithChildren } from "react";
import { KlimraTheme } from "styles/theme";

const useStyles = createUseStyles((theme: Theme) => ({
  benefitsContainer: {
    backgroundColor: (props: Props) => theme.colors[props.bg || 'backgroundPrimary'],
    display: "grid",
    gridTemplateColumns: "repeat(2, 50%)",
    gridTemplateRows: "fit-content(1fr)",
    padding: "10vmin",
    "@media (max-width: 1100px)": {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto",
      "& > *:first-child": {
        marginTop: "2rem",
      },
    },
    "@media (max-width: 700px)": {
      padding: "3vmin",
    },
    "@media (max-width: 600px)": {
      padding: "1vmin",
    },
  },
  leftSideContent: {
    backgroundColor: (props: Props) => theme.colors[props.containerBg || 'backgroundPrimary'],
    borderRadius: "15px 0 0 15px",
    padding: "3rem",
    display: "flex",
    flexDirection: "column",
    "& ul": {
      fontSize: "1rem",
      paddingLeft: 0,
      marginLeft: "15px",
      marginBottom: "2rem",
    },
    "& a": {
      marginLeft: "0",
    },
    "& p": {
      fontSize: "1rem",
    },
    order: 1,
    "@media (max-width: 1100px)": {
      order: 2,
      marginBottom: "2rem",
      borderRadius: "0 0 15px 15px",
    },
    "@media (max-width: 700px)": {
      padding: "2rem",
      paddingBottom: "3rem",
      "& h1": {
        fontSize: "1.5rem",
      },
    },
    "@media (max-width: 600px)": {
      padding: "1rem",
      paddingBottom: "3rem",
    },
  },
  rightSideContent: {
    borderRadius: "0 15px 15px 0",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "600px",
    order: 2,
    "@media (max-width: 1100px)": {
      order: 1,
      maxHeight: "400px",
      borderRadius: "15px 15px 0 0",
    },
  },
}));

interface Props {
  title: string;
  imageSrc: string;
  imageAlt?: string;
  bg?: keyof typeof KlimraTheme.colors;
  containerBg?: keyof typeof KlimraTheme.colors;
}

export const InfoSlideWithImage: React.FC<PropsWithChildren<Props>> = ({ title, imageSrc, imageAlt, children, bg="backgroundLightGrey", containerBg="backgroundPrimary" }): JSX.Element => {
  const classes = useStyles({ bg, title, imageSrc, imageAlt, containerBg });

  return (
    <div className={classes.benefitsContainer}>
      <img src={imageSrc} alt={imageAlt} className={classes.rightSideContent} />
      <div className={classes.leftSideContent}>
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  );
};
