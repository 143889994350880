import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { createUseStyles, Theme } from "react-jss";

const useStyles = createUseStyles((theme: Theme) => ({
  footerBox: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: theme.colors.secondary,
    color: theme.colors.textSecondary,
    textAlign: "center",
    padding: ".4rem 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.25rem",
    marginTop: "auto",
  },
  footerIcon: {
    width: "2.2rem",
    height: "auto",
    marginRight: "0.75rem",
    flexShrink: 0,
    marginLeft: "0.5rem",
  },
  footerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 600px)": {
      fontSize: "4vw",
    },
  },
}));

export interface PropsBannerFooter {
  icon?: IconDefinition;
  text: string;
}

export const BannerFooter: React.FC<PropsBannerFooter> = ({ icon, text }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.footerBox}>
      {icon && <FontAwesomeIcon icon={icon} className={classes.footerIcon}/>}
      <span className={classes.footerText}>{text}</span>
    </div>
  );
};
